import { Button, Box, DialogActions, DialogContent, Divider, Icon, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { Form, FormikProps } from 'formik'
import createFormikHandlers from '../common/createFormikHandlers'
import moment from 'moment'
import SearchVenue from '../common/SearchVenue'
import { Gig } from '../../graphql'
import { ImageUploadContainer } from '../common'
import { InitialState } from './AddEvent'
import { useMemo } from 'react'
import { useAuth } from '../auth/AuthProvider'

function Gigs({ isAuthorized, gigs, onEdit, onDelete, onAdd }: React.PropsWithChildren<{ isAuthorized: boolean, gigs: Gig[], onEdit: (gig: Gig) => void, onDelete: (gig: Gig) => void, onAdd: () => void }>) {
  const { user } = useAuth()
  return <Table size="small">
    <TableHead>
      <TableRow><TableCell>Artiest</TableCell><TableCell align="right">Starttijd</TableCell><TableCell align="right">Speeltijd</TableCell><TableCell /></TableRow>
    </TableHead>
    <TableBody>
      {gigs.map((gig, k) => <TableRow key={k}>
        <TableCell>{gig.artist.name}</TableCell>
        <TableCell align="right">
          {moment(gig.from).format('H:mm')}&nbsp;
        </TableCell>
        <TableCell align="right">
          {moment(gig.to).diff(gig.from, 'minutes')} min&nbsp;
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          {(!gig.owner || gig.owner === '' || gig.owner === user?.uid) && <IconButton onClick={() => onEdit(gig)} size="small" color="primary"><Icon fontSize="small">create</Icon></IconButton>}
          {(!gig.owner || gig.owner === '' || gig.owner === user?.uid) && <IconButton onClick={() => onDelete(gig)} size="small" color="secondary"><Icon fontSize="small">delete</Icon></IconButton>}
        </TableCell>
      </TableRow>)}
      {isAuthorized && <TableRow>
        <TableCell colSpan={4}>
          <Button size="small" onClick={() => onAdd()}><Icon>add_circle</Icon>&nbsp;&nbsp;{gigs.length > 0 ? 'Nog een optreden toevoegen' : 'Optreden toevoegen'}</Button>
        </TableCell>
      </TableRow>}
    </TableBody>
  </Table>
}

type AddEventFormProps = {
  formik: FormikProps<InitialState>
  onGoBack: (state: any) => void
  onAddGig: () => void
  onEditGig: (gig: Gig) => void
  onDeleteGig: (gig: Gig) => void
  disableVenue?: boolean
  hidePreviousButton?: boolean
}

function AddEventForm({ hidePreviousButton, disableVenue, onGoBack, onAddGig, onEditGig, onDeleteGig, formik: { values: { gigs, ...values }, ...formik } }: AddEventFormProps) {
  const { user } = useAuth()
  const isAuthorized = useMemo(() => !values.owner || values.owner === '' || user?.uid === values.owner, [user, values])
  return <Form style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
    <Box sx={{ overflow: 'auto' }}>
      <ImageUploadContainer uploadText="Upload een cover afbeelding" onChange={file => formik.setFieldValue('cover', file)} value={values.cover instanceof File ? URL.createObjectURL(values.cover) : values.cover as string}>
        <TextField
          {...createFormikHandlers({ fieldName: 'title', values, ...formik })}
          label="Event naam"
          fullWidth
          required
          disabled={!isAuthorized}
        />
      </ImageUploadContainer>
      <DialogContent sx={{ overflow: 'initial', paddingTop: 0 }}>
        <SearchVenue
          value={values.venue}
          onChange={(event, newValue) => {
            if (typeof newValue !== 'string') {
              formik.setFieldValue('venue', newValue)
            }
          }}
          disabled={!isAuthorized || disableVenue}
          error={formik.errors.venue as string}
        />
        <TextField
          {...createFormikHandlers({ fieldName: 'price', values, ...formik })}
          label="Entreeprijs"
          fullWidth
          type="number"
          inputProps={{ pattern: '\\d*', inputMode: 'numeric' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
          }}
          disabled={!isAuthorized}
        />
        <TextField
          {...createFormikHandlers({ fieldName: 'description', values, ...formik })}
          label="Opmerkingen"
          fullWidth
          multiline
          rows={5}
          disabled={!isAuthorized}
        />
      </DialogContent>
      <Divider>Programma</Divider>
      <Gigs isAuthorized={isAuthorized} gigs={gigs as Gig[]} onEdit={onEditGig} onDelete={onDeleteGig} onAdd={onAddGig} />
    </Box>
    <DialogActions>
      {!hidePreviousButton ? <Button onClick={onGoBack}>Vorige</Button> : null}
      <span style={{ flex: 1 }} />
      <Button type="submit" variant="contained" size="small" disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}>Opslaan</Button>
    </DialogActions>
  </Form>
}

export default AddEventForm
