import { getAuth } from 'firebase/auth'
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import React from 'react'
import cacheOptions from '../fragmentTypes.json'

export function createApolloClient(getToken: () => string | Promise<string> | undefined) {
  const cache = new InMemoryCache(cacheOptions)
  const httpLink = new HttpLink({
    uri: `/api`,
    fetch: async (uri, options: any) => {
      const accessToken = await getToken()
      options.headers.Authorization = `Bearer ${accessToken}`
      return fetch(uri, options)
    },
  })
  return new ApolloClient({
    link: ApolloLink.from([
      createOmitTypenameLink(),
      httpLink
    ]),
    cache
  })
}

const apolloClient = createApolloClient(() => getAuth().currentUser?.getIdToken())

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ children, client }: React.PropsWithChildren<{ client?: ApolloClient<any> }>) {
  return <ApolloProvider client={client || apolloClient}>{children}</ApolloProvider>
}

function omitTypename(key: string, value: string) {
  return key === '__typename' ? undefined : value
}

function createOmitTypenameLink() {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }

    return forward(operation)
  })
}
