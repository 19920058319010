import { MobileDateTimePicker } from '@mui/lab'
import { Button, DialogContent, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import moment from 'moment'
import { useCallback } from 'react'
import { useLocation } from 'react-router'
import { Artist, Event, Gig, ProfileType, useFindEventsByVenueAndDateQuery, Venue } from '../../graphql'
import SearchVenue from '../common/SearchVenue'


function SearchResults({ venue, from, onAddToExisting }: { venue: Venue, from: Date, onAddToExisting: (event: Event, from: Date) => void }) {
  const { data: { findEventsByVenueAndDate: results } = { findEventsByVenueAndDate: [] }, loading } = useFindEventsByVenueAndDateQuery({ variables: { venueId: venue.id, date: from }, fetchPolicy: 'no-cache' })

  return loading ? null : results.length > 0 ? <>
    <Divider />
    <DialogContent sx={{ overflow: 'initial', flex: 0 }}>
      <Typography variant="subtitle2">Optredens op {moment(from).format('D MMMM')} bij {venue.name}</Typography>
      <List>
        {results.map((event, k) => <ListItem key={k}>{/* TODO: if artist is in gigs list of an event, mark it! */}
          <ListItemText primary={event.title} />
          <ListItemSecondaryAction><Button onClick={() => onAddToExisting(event as any, from)}>Toevoegen aan dit event</Button></ListItemSecondaryAction>
        </ListItem>)}
      </List>
    </DialogContent>
    <Divider>OF</Divider>
  </> : <>
    <Divider />
    <DialogContent sx={{ overflow: 'initial', flex: 0 }}>
      <Typography variant="subtitle2" align="center">Geen optredens gevonden op {moment(from).format('D MMMM')} bij {venue.name}</Typography>
    </DialogContent>
  </>
}

type SearchEventProps = {
  onSubmit: (event: Event, from: Date, venue: Venue) => void
}

function SearchEvent({ onSubmit }: SearchEventProps) {
  const { state: { from, venue, profile } = { venue: null, from: null } } = useLocation<{ referer?: string, from: Date, venue: Venue, profile?: { name: string, id: string, type: ProfileType } }>()

  const handleAddNew = useCallback(({ from, venue }: { from: Date, venue: Venue }) => {
    onSubmit({ venue, gigs: [{ artist: profile as Artist, from, to: moment(from).add(60, 'minutes').toDate() }] } as Event, from, venue)
  }, [profile, onSubmit])

  const handleAddToExisting = useCallback((event: Event, from: Date) => {
    onSubmit({ ...event, gigs: [...event.gigs, { artist: profile as Artist, from: from as Date, to: moment(from).add(60, 'minutes').toDate() } as Gig] }, from, event.venue)
  }, [profile, onSubmit])

  return <Formik
    initialValues={{ from, venue } as any}
    onSubmit={handleAddNew}>
    {({ values: { from, venue }, ...formik }) => <Form style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
      <DialogContent sx={{ overflow: 'initial', flex: 0 }}>
        <MobileDateTimePicker
          label="Wanneer is het event"
          inputFormat="D MMMM HH:mm"
          cancelText="Annuleren"
          okText="OK"
          todayText="Vandaag"
          disableCloseOnSelect={false}
          onChange={value => formik.setFieldValue('from', value ? new Date(value) : null)}
          value={from || null}
          renderInput={(params) => <TextField {...params} fullWidth required />}
          minutesStep={5}
        />
        <SearchVenue
          value={venue || null}
          onChange={(event, newValue) => {
            if (typeof newValue !== 'string') {
              formik.setFieldValue('venue', newValue as any)
            }
          }}
        />
      </DialogContent>
      {venue && from && <SearchResults venue={venue} from={from} onAddToExisting={handleAddToExisting} />}
      <DialogContent sx={{ overflow: 'initial', flex: 0, display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" disabled={!from || !venue} type="submit">Nieuwe event maken</Button>
      </DialogContent>
    </Form>}
  </Formik>
}

export default SearchEvent
