import React, { useContext } from 'react'
import { initializeApp, FirebaseApp } from 'firebase/app'
import { initializeAnalytics, isSupported } from 'firebase/analytics'
import { initializePerformance } from 'firebase/performance'
import 'firebase/storage'

const firebaseApp = initializeApp(require('../firebase-config.json'))
isSupported().then((isSupported) => isSupported && initializeAnalytics(firebaseApp))
initializePerformance(firebaseApp)

const FirebaseContext = React.createContext<FirebaseApp | null>(null)

function FirebaseProvider({ app, children }: React.PropsWithChildren<{ app?: FirebaseApp }>) {
  return <FirebaseContext.Provider value={app || firebaseApp}>{children}</FirebaseContext.Provider>
}

export function useFirebase() {
  const app = useContext(FirebaseContext)
  return app
}

export default FirebaseProvider
