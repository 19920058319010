import { Box, Card, CardActionArea, CardContent, CardMedia, Icon, ImageListItem, ImageListItemBar, List, ListItem, ListItemIcon, ListItemText, Typography, styled, Divider, ButtonGroup, Button, Avatar } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { Event } from '../../graphql'
import moment from 'moment'

const StyledCardMedia = styled(CardMedia)({
  width: 160,
  height: 160,
  display: 'flex',
  flexWrap: 'wrap',
  padding: 0
})

function EventCard({ event: { id, title, gigs, venue, from, square }, editMode = false, onEdit, onDelete }: { event: Event, editMode?: boolean, onEdit?: () => void, onDelete?: () => void }) {
  const history = useHistory()
  const { pathname } = useLocation()
  return <Card style={{ margin: 8 }}>
    <CardActionArea disabled={editMode} onClick={() => history.push(`/events/${id}`, { referer: pathname })} style={{ display: 'flex', alignItems: 'start' }}>
      <Box width={160}>
        <StyledCardMedia
          {...gigs.length === 1 ? { image: gigs[0].square || square || '' } : {
            children: [
              gigs.slice(0, gigs.length > 4 ? 3 : 4).map((gig, n) => <ImageListItem key={n} style={{ width: 80, height: 80 }}>
                <Avatar sx={{ width: 80, height: 80 }} variant="square" src={gig.square || gig.artist.avatar || ''} alt="" />
              </ImageListItem>),
              gigs.length > 4 && <ImageListItem key="more" style={{ width: 80 }}><ImageListItemBar title={<Typography variant="h5" align="center">+{gigs.length - 3}</Typography>} subtitle={<Typography variant="subtitle2" align="center">more</Typography>} style={{ height: 80 }} /></ImageListItem>
            ]
          }}
        />
      </Box>
      <Box flex={1} display="flex" flexDirection="column">
        <CardContent style={{ paddingBottom: 8 }}>
          <Typography variant="h6">{title}</Typography>
        </CardContent>
        <Box flex={1}>
          <List disablePadding dense>
            <ListItem>
              <ListItemIcon style={{ minWidth: 36 }}><Icon color="disabled">place</Icon></ListItemIcon>
              <ListItemText primary={<Typography component="span" color="textSecondary" variant="body2" noWrap>{venue.name}</Typography>} />
            </ListItem>
            <ListItem>
              <ListItemIcon style={{ minWidth: 36 }}><Icon color="disabled">today</Icon></ListItemIcon>
              <ListItemText primary={<Typography component="span" color="textSecondary" variant="body2" noWrap>{moment(from).format('D MMMM YYYY')}</Typography>} />
            </ListItem>
          </List>
        </Box>
      </Box>
    </CardActionArea>
    {editMode && <>
      <Divider />
      <ButtonGroup variant="text" aria-label="text button group" fullWidth>
        <Button color="primary" onClick={onEdit}>Bewerken</Button>
        <Button color="secondary" onClick={onDelete}>Verwijderen</Button>
      </ButtonGroup>
    </>}
  </Card>
}

export default EventCard
