import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { useCallback, useContext, useState } from 'react'

const ConfirmationDialogContext = React.createContext<{ open: (options: { onConfirm: () => Promise<void>, onCancel?: () => void, dialogContent?: string }) => void }>({ open: () => { } })

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />
})

export function useConfirmationDialog() {
  const context = useContext(ConfirmationDialogContext)
  return context
}

function ConfirmationDialogProvider({ children }: React.PropsWithChildren<any>) {
  const [state, setState] = useState<{ open: boolean, dialogContent?: string, onConfirm: () => Promise<void>, onCancel?: () => void }>({ open: false, onConfirm: async () => { } })
  const handleOpen = useCallback(({ onConfirm, onCancel, dialogContent }: { onConfirm: () => Promise<void>, onCancel?: () => void, dialogContent?: string }) => {
    setState({ open: true, onConfirm, onCancel, dialogContent })
  }, [])
  const handleClose = useCallback(() => {
    state.onCancel && state.onCancel()
    setState({ open: false, onConfirm: async () => { }, onCancel: undefined, dialogContent: undefined })
  }, [state])
  const handleConfirm = useCallback(() => {
    state.onConfirm()
    setState({ open: false, onConfirm: async () => { }, onCancel: undefined, dialogContent: undefined })
  }, [state])
  return <ConfirmationDialogContext.Provider value={{ open: handleOpen }}>
    {children}
    <Dialog open={state.open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>Verwijderen</DialogTitle>
      <DialogContent>
        <DialogContentText>{state.dialogContent ? state.dialogContent : 'Weet je zeker dat je dit item wilt verwijderen?'}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Annuleren</Button>
        <Button onClick={handleConfirm} color="primary">Verwijderen</Button>
      </DialogActions>
    </Dialog>
  </ConfirmationDialogContext.Provider>
}

export default ConfirmationDialogProvider
