import { Box, Icon, IconButton, Slider, Typography } from '@mui/material'
import ReactAvatarEditor from 'react-avatar-editor'
import { useState, useRef, useCallback, useEffect } from 'react'
import Dropzone from 'react-dropzone'

let timeout: NodeJS.Timeout

function AvatarEditor({ value, onChange }: { value?: string | null, onChange?: (imageUrl: string | null) => void }) {
  const [scale, setScale] = useState(1.2)
  const [files, setFiles] = useState<File[]>([])
  const editorRef = useRef<ReactAvatarEditor>(null)

  useEffect(() => {
    console.log('value', value)
  }, [value])

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles)
  }, [])

  const handleChange = useCallback(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      const imageUrl = editorRef.current?.getImageScaledToCanvas().toDataURL()
      onChange && onChange(imageUrl || null)
    }, 100)
  }, [editorRef, onChange])

  const handleRemove = useCallback(() => {
    setFiles([])
    onChange && onChange(null)
  }, [onChange])

  return <Dropzone onDrop={handleDrop} maxFiles={1} multiple={false} noClick={files.length > 0} accept="image/*">
    {({ getRootProps, getInputProps, open }) => (
      <Box sx={{ height: 292, display: 'flex', flexDirection: 'column', background: 'rgba(0,0,0,0.4)' }}>
        <Box {...getRootProps()} sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ position: 'relative', flex: 1, height: '100%', display: 'flex', overflow: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
            {files.length > 0 ? <>
              <ReactAvatarEditor
                ref={editorRef}
                image={files[0]}
                width={192}
                height={192}
                border={[150, 50]}
                rotate={0}
                scale={scale}
                borderRadius={96}
                onImageReady={handleChange}
                onImageChange={handleChange}
              />
              <IconButton onClick={open} sx={{ position: 'absolute', top: 8, left: 8 }}>
                <Icon fontSize="large">change_circle</Icon>
              </IconButton>
              <IconButton onClick={handleRemove} sx={{ position: 'absolute', top: 8, right: 8 }}>
                <Icon fontSize="large">cancel</Icon>
              </IconButton>
              <Slider
                size="small"
                value={scale}
                step={0.1}
                min={1}
                max={3}
                onChange={(e, v) => setScale(v as number)}
                valueLabelDisplay="auto"
                sx={{ position: 'absolute', bottom: 8, width: 284 }}
              />
            </> : value ? <>
              <img src={value} alt="Avatar" style={{ borderRadius: 96 }} />
              <IconButton onClick={open} sx={{ position: 'absolute', top: 8, left: 8 }}>
                <Icon fontSize="large">change_circle</Icon>
              </IconButton>
              <IconButton onClick={handleRemove} sx={{ position: 'absolute', top: 8, right: 8 }}>
                <Icon fontSize="large">cancel</Icon>
              </IconButton>
            </> : <Typography>Upload een afbeelding</Typography>}
          </Box>
          <input {...getInputProps()} />
        </Box>
      </Box>
    )}
  </Dropzone>
}

export default AvatarEditor
