import React, { useCallback, useMemo } from 'react'
import { useLocation, useRouteMatch, useHistory, useParams } from 'react-router-dom'
import { CommonDialog, LoadingPopover } from '../common'
import { CreateEventInput, FindEventsByArtistQueryDocument, FindEventsByVenueQueryDocument, Gig, ProfileType, useCreateEventMutation, useUpdateEventMutation, Venue, Event, Artist, EventsQueryDocument } from '../../graphql'
import SearchEvent from './SearchEvent'
import AddEventForm from './AddEventForm'
import { readAndCompressImage } from 'browser-image-resizer'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useAuth } from '../auth/AuthProvider'
import { Formik, FormikProps } from 'formik'
import { AddGig, QueryEvent } from '.'

export type InitialState = Omit<Event, 'cover' | 'id' | 'gigs'> & { cover?: string | File, id?: string, gigs: Array<Omit<Gig, 'cover'> & { cover?: string | File | null }> }

const initialValues = {
  title: '',
  from: null as Date | null,
  to: null as Date | null,
  venue: null as Venue | null,
  description: '',
  price: '',
  cover: null as File | null,
  gigs: [] as Gig[]
} as unknown as InitialState

const initialGigValues = {
  artist: null as Artist | null,
  from: null as Date | null,
  to: null as Date | null
}

function validateEvent(values: InitialState) {
  const errors: any = {}
  if (!values.title || values.title === '') {
    errors.title = 'Dit veld is verplicht'
  }
  if (!values.venue) {
    errors.venue = 'Kies een podium'
  }
  return errors
}

function AddEvent() {
  const location = useLocation<{ referer?: string, gig: Gig, profile?: Artist | Venue }>()
  const routeMatch = useRouteMatch()
  const history = useHistory()
  const [createEventMutation] = useCreateEventMutation()
  const [updateEventMutation] = useUpdateEventMutation()
  const open = useMemo(() => ['/events/add', '/events/edit/:eventId', '/events/edit/:eventId/gigs/add'].indexOf(routeMatch.path) >= 0, [routeMatch])
  const { user } = useAuth()
  const isArtist = useMemo(() => location.state?.profile?.type === ProfileType.Artist, [location.state])
  const { eventId } = useParams<{ eventId?: string }>()
  const initialEventValues = useMemo(() => ({ ...initialValues, ...!isArtist && location.state?.profile && location.state?.profile?.type === ProfileType.Venue ? { venue: location.state?.profile as Venue } : {} }), [location.state, isArtist])

  const handleSubmit = useCallback(async ({ id, from, to, venue, price, gigs, cover, square, owner, ...e }: InitialState) => {
    let eventImages = { cover: cover as string, square }
    if (cover instanceof File) {
      eventImages = await uploadCover(cover, `images/${user?.uid}`)
    }
    const promisses = gigs.map(async ({ artist, owner, cover, square, ...g }) => {
      let gigIimages = { cover: cover as string, square }
      if (cover instanceof File) {
        gigIimages = await uploadCover(cover, `images/${user?.uid}`)
      }
      return { ...g, ...gigIimages, artistId: artist.id }
    })
    const data: CreateEventInput = { ...e, ...eventImages, price: Number(price), venueId: venue?.id || '', gigs: await Promise.all(promisses) }
    const refetchQueries = [
      { query: EventsQueryDocument },
      { query: FindEventsByVenueQueryDocument, variables: { venueId: venue.id } },
      ...gigs.map(({ artist }) => ({ query: FindEventsByArtistQueryDocument, variables: { artistId: artist.id } }))
    ]
    if (id) {
      await updateEventMutation({ variables: { data: { id, ...data } }, refetchQueries, awaitRefetchQueries: true })
    } else {
      await createEventMutation({ variables: { data }, refetchQueries, awaitRefetchQueries: true })
    }
    history.push(location.state?.referer || '/', {})
  }, [createEventMutation, updateEventMutation, history, location.state, user])

  const handleEvent = useCallback((event: { id: string }, from: Date, venue: Venue, cb: () => void) => {
    history.push(`/events/${event.id ? `edit/${event.id}` : 'add'}`, { ...location.state, from, venue })
    cb()
  }, [location.state, history])

  const handleSubmitGig = useCallback((gig: Omit<Gig, 'cover'> & { cover: File | string }, { values, ...formik }: FormikProps<InitialState>) => {
    const index = values.gigs.indexOf(location.state?.gig)
    const gigs = [...values.gigs]
    if (index >= 0) {
      gigs.splice(index, 1, { ...gig, from: new Date(gig.from), to: new Date(gig.to) })
    } else {
      gigs.push({ ...gig, from: new Date(gig.from), to: new Date(gig.to) })
    }
    formik.setFieldValue('gigs', gigs)
    history.push(location.pathname, { ...location.state, gig: undefined })
  }, [history, location.state, location.pathname])

  const handleEditGig = useCallback((gig: Gig = initialGigValues as unknown as Gig) => {
    history.push(location.pathname, { ...location.state, gig })
  }, [history, location])

  const handleDeleteGig = useCallback((gig: Gig, { values, ...formik }: FormikProps<InitialState>) => {
    const index = values.gigs.indexOf(gig)
    const gigs = [...values.gigs]
    if (index >= 0) {
      gigs.splice(index, 1)
    }
    formik.setFieldValue('gigs', gigs)
  }, [])

  return <CommonDialog open={open} title={eventId ? 'Event bewerken' : 'Voeg jouw event toe'}>
    {open && <Formik
      initialValues={initialEventValues}
      validate={values => validateEvent(values)}
      onSubmit={handleSubmit}>
      {({ values, ...formik }) => <>
        {formik.isSubmitting && <LoadingPopover />}
        {!values.id && eventId && <QueryEvent initialValues={initialEventValues} onLoad={formik.setValues} />}
        {!eventId && isArtist && initialEventValues === values && !location.state?.gig && <SearchEvent
          onSubmit={(e, f, v) => handleEvent(e, f, v, () => formik.setValues({ ...values, ...e } as InitialState))}
        />}
        {(eventId || !isArtist || initialEventValues !== values) && !location.state?.gig && <AddEventForm
          hidePreviousButton={!!values.id || !isArtist}
          disableVenue={values.venue === location.state?.profile}
          formik={{ values, ...formik }}
          onAddGig={handleEditGig}
          onEditGig={handleEditGig}
          onDeleteGig={gig => handleDeleteGig(gig, { values, ...formik })}
          onGoBack={() => formik.setValues(initialEventValues)}
        />}
        {location.state?.gig && <AddGig
          gig={location.state?.gig}
          isEditing={!!location.state?.gig?.id || values.gigs.indexOf(location.state?.gig) >= 0}
          onSubmit={gig => handleSubmitGig(gig, { values, ...formik })}
          onGoBack={() => history.push(location.pathname, { ...location.state, gig: undefined })}
        />}
      </>}
    </Formik>}
  </CommonDialog>
}

export default AddEvent

const squareConfig = {
  quality: 1,
  width: 200,
  height: 200
}

async function uploadCover(cover: File, path: string) {
  const square: File = await readAndCompressImage(cover, squareConfig)
  const storage = getStorage()
  const coverRef = ref(storage, `${path}/${cover.name}`)
  const squareRef = ref(storage, `${path}/square_${cover.name}`)
  const promisses: Array<Promise<{ cover: string } | { square: string }>> = []
  promisses.push(uploadBytes(coverRef, cover).then(async r => ({ cover: await getDownloadURL(r.ref) })))
  promisses.push(uploadBytes(squareRef, square).then(async r => ({ square: await getDownloadURL(r.ref) })))
  const results = await Promise.all(promisses)
  return results.reduce((images: { cover: string, square: string }, r) => ({ ...images, ...r }), {} as unknown as { cover: string, square: string })
}
