import { Autocomplete, AutocompleteRenderInputParams, CircularProgress, TextField } from '@mui/material'
import { useCallback, useState } from 'react'
import { useSearchArtistsQueryLazyQuery, Artist } from '../../graphql'

let timeout: NodeJS.Timeout

function SearchArtist({ disabled, label, error, value, onChange }: { disabled?: boolean, label?: string, error?: string, value: Artist | null, onChange?: ((event: React.SyntheticEvent<Element, Event>, value: Artist | null) => void) }) {
  const [searchVenues, { data: { searchArtists: searchResults } = { searchArtists: [] }, loading }] = useSearchArtistsQueryLazyQuery()
  const [typing, setTyping] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)

  const handleSearchArtist = useCallback((q: string) => {
    setTyping(true)
    setHasSearched(true)
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setTyping(false)
      searchVenues({ variables: { q } })
    }, 300)
  }, [searchVenues])

  return <Autocomplete
    options={searchResults}
    value={value}
    onInputChange={(e, v) => handleSearchArtist(v)}
    onChange={onChange}
    loading={loading || typing}
    loadingText="Zoeken..."
    filterOptions={(x) => x}
    noOptionsText={!hasSearched ? 'Typ om te zoeken...' : 'Geen artiesten gevonden'}
    getOptionLabel={(option: Artist) => option.name}
    disabled={disabled}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    renderInput={(params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label={label || 'Selecteer een artiest'}
        required
        error={Boolean(error)}
        helperText={error}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading || typing ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
  />
}

export default SearchArtist
