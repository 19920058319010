import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation, useParams } from 'react-router-dom'
import Auth from './modules/auth/Auth'
import { EventDetails, EventsOverview, AddEvent } from './modules/events'
import AuthProvider, { useAuth } from './modules/auth/AuthProvider' // TODO: move AuthProvider into config?
import { About, Dashboard, Loading, RenderMarkdown } from './modules/common'
import { atom, useAtom } from 'jotai'
import { policiesAtom } from './atoms'
import AppProviders from './config'
import { Box } from '@mui/material'
import { AddProfile, ArtistProfile, ProfileDashboard, VenueProfile } from './modules/profiles'
import ConfirmationDialogProvider from './modules/common/ConfirmationDialog'
import { Artist, ProfileType, useFindProfileQuery, Venue } from './graphql'

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <React.Suspense fallback={<Loading />}>
        <Router>
          <AuthProvider>
            <ConfirmationDialogProvider>
              <AppRoutes />
            </ConfirmationDialogProvider>
          </AuthProvider>
        </Router>
      </React.Suspense>
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

function AppRoutes() {
  const { user } = useAuth()
  const location = useLocation<{ referer?: string }>()
  return <Switch>
    {!user && <Route path={['/signup', '/login']} component={Auth} />}
    <Route>
      <App>
        <React.Suspense fallback={<Loading />}>
          <Switch location={{ ...location, pathname: location.state?.referer || location.pathname }}>
            {user && <Route path={['/signup', '/login']}><Redirect to="/" /></Route>}
            <Route path="/account/settings">account settings</Route>
            <Route path="/about" exact component={About} />
            <Route path={['/terms', '/privacy']} exact component={PoliciesRoutes} />
            <Route path={['/events/add', '/events/edit/:eventId/gigs/edit/:gigId', '/events/edit/:eventId/gigs/add', '/events/edit/:eventId', '/events/:eventId', '/events', '/profile/add/:type?', '/profile/:profileId/edit', '/profile/:profileId', '/browse/:search?', '/']} exact component={EventsRoutes} />
            <Route path={['/:type(artist|venue)/:profileId', '/:slug']} exact component={ProfileRoutes} />
            <Route>Not found...</Route>
          </Switch>
          <Route path={['/events/add', '/events/edit/:eventId/gigs/edit/:gigId', '/events/edit/:eventId/gigs/add', '/events/edit/:eventId', '/events/:eventId', '/profile/add/:type?', '/profile/:profileId/edit']}>
            <EventDetails />
            <AddEvent />
            <AddProfile />
          </Route>
        </React.Suspense>
      </App>
    </Route>
  </Switch>
}

function EventsRoutes() {
  const { user } = useAuth()
  return <Switch>
    {user && <Route path={['/profile/:profileId', '/profile/:profileId/edit']} component={ProfileDashboard} exact />}
    {user && <Route path={['/profile/add/:type?', '/']} component={Dashboard} exact />}
    <Route path={['/events', '/browse/:search', '/browse', '/']} component={EventsOverview} />
  </Switch>
}

function ProfileRoutes() {
  const { slug, type, profileId } = useParams<{ slug?: string, type?: ProfileType, profileId?: string }>()
  const { data: { findProfile: profile } = { findProfile: undefined }, loading } = useFindProfileQuery({ variables: { id: profileId || slug, type: type ? type.toUpperCase() as ProfileType : undefined } })
  return loading ? <Loading /> : !profile ? <span>profile not found...</span> :
    <Switch>
      <Route path="/artist/:profileId"><ArtistProfile artist={profile as Artist} /></Route>
      <Route path="/venue/:profileId"><VenueProfile venue={profile as Venue} /></Route>
      {profile && <Route path="/:slug">{profile.type === ProfileType.Artist ? <ArtistProfile artist={profile as Artist} /> : <VenueProfile venue={profile as Venue} />}</Route>}
    </Switch>
}

function PoliciesRoutes() {
  const [policies] = useAtom(policiesAtom)
  const { pathname } = useLocation()
  const policy = useMemo(() => policies.find(p => {
    const fileName = pathname === '/terms' ? 'terms_of_service.md' : 'privacy_policy.md'
    return p.name === fileName
  }), [pathname, policies])
  const [content] = useAtom(policy?.content || atom(async () => ''))
  return <Box padding={2}><RenderMarkdown>{content}</RenderMarkdown></Box>
}
