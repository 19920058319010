import { AppBar as MuiAppBar, Toolbar, styled } from '@mui/material'
import React from 'react'

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'start',
  }
}))

const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '50%',
    left: 0
  }
}))

function AppBar({ children }: React.PropsWithChildren<{}>) {
  return <StyledAppBar position="absolute" color="transparent" elevation={0}>
    <StyledToolbar disableGutters>
      {children}
    </StyledToolbar>
  </StyledAppBar>
}

export default AppBar
