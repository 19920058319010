import { Chip } from '@mui/material'
import MuiAutocomplete, { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete'

function MultiAutocomplete({ allowNew = true, ...props }: { allowNew?: boolean } & AutocompleteProps<{ title: string, isNew?: boolean }, true, false, false>) {
  const filter = createFilterOptions<{ title: string, isNew?: boolean }>()

  return <MuiAutocomplete
    multiple
    filterOptions={(options, params) => {
      const filtered = filter(options, params)
      const { inputValue } = params
      const isExisting = options.some((option) => inputValue === option.title)
      if (inputValue !== '' && !isExisting && allowNew) {
        filtered.push({
          isNew: true,
          title: inputValue,
        })
      }
      return filtered
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    getOptionLabel={(option: { title: string, isNew?: boolean }) => option.title}
    renderOption={(props, option) => <li {...props}>{option.isNew ? `"${option.title}" toevoegen` : option.title}</li>}
    renderTags={(value, getTagProps) => value.map(({ title }, index) => <Chip variant="outlined" label={title} {...getTagProps({ index })} size="small" />)}
    {...props}
  />
}

export default MultiAutocomplete
