import React from 'react'
import './moment'
import FirebaseProvider from './firebase'
import ApolloProvider from './apollo'
import ThemeProvider from './theme'

function combineProviders(...components: Array<(props: React.PropsWithChildren<any>) => JSX.Element>) {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      const combined = ({ children }: React.PropsWithChildren<any>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
      return combined
    },
    ({ children }: React.PropsWithChildren<{}>) => <>{children}</>,
  )
}

export default combineProviders(FirebaseProvider, ApolloProvider, ThemeProvider);
