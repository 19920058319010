import React, { useMemo, useCallback } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Button, Box, Dialog, DialogContent, Zoom, IconButton, Icon, useTheme, useMediaQuery, ListItemSecondaryAction, Typography, Divider, ListSubheader } from '@mui/material'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import { DialogTitle } from '../common'
import EventDetailsCardMedia from './EventDetailsCardMedia'
import { useEventQuery, Gig } from '../../graphql'

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
  return <Zoom ref={ref} {...props} />
})

function EventDetails() {
  const history = useHistory()
  const location = useLocation<{ referer?: string }>()
  const { eventId } = useParams<{ eventId?: string }>()
  const open = useMemo(() => location.pathname.indexOf('/events') === 0 && location.pathname.indexOf('/events/edit') < 0 && typeof eventId !== 'undefined' && eventId !== null, [eventId, location.pathname])
  const theme = useTheme()
  const { data: { event } = { event: undefined } } = useEventQuery({ variables: { eventId: eventId || '' } })
  const selectedGig = useMemo(() => eventId && eventId !== (event?.id + '') ? event?.gigs.find(g => g.id + '' === eventId) : event?.gigs.length === 1 ? event?.gigs[0] : undefined, [event, eventId])
  const showBack = useMemo(() => selectedGig?.id + '' === eventId, [selectedGig, eventId])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLandscape = useMediaQuery(theme.breakpoints.down('md'))
  const gigsByDay = useMemo(() => event?.gigs.reduce((gigsByDay, gig) => {
    const index = gigsByDay.findIndex(({ date }) => moment(date).isSame(gig.from, 'day'))
    if (index >= 0) {
      gigsByDay[index].gigs.push(gig)
    } else {
      gigsByDay.push({ date: gig.from, gigs: [gig] })
    }
    return gigsByDay
  }, [] as Array<{ date: Date, gigs: Gig[] }>).sort((a, b) => a.date > b.date ? 1 : b.date > a.date ? -1 : 0), [event])

  const handleClose = useCallback(() => {
    history.push(location.state && location.state.referer ? location.state.referer : '/')
  }, [history, location])

  const handleGoBack = useCallback(() => {
    if (showBack) {
      history.push(`/events/${event?.id}`, location.state)
    } else {
      handleClose()
    }
  }, [history, showBack, event, location, handleClose])

  return <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    fullScreen={isLandscape}
    fullWidth
    maxWidth="md"
  >
    <DialogTitle>
      {(isLandscape || showBack) && <IconButton onClick={handleGoBack}><Icon>arrow_back</Icon></IconButton>}
      {isLandscape && <Typography variant="h5" style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>Band-Base</Typography>}
      <span />
      {!isLandscape && <IconButton onClick={handleClose}><Icon>close</Icon></IconButton>}
    </DialogTitle>
    <Box height="100%" overflow="auto" display="flex" flexDirection={isMobile ? 'column' : 'row'}>
      <Box display="flex" flexDirection="column" flex={1}>
        <EventDetailsCardMedia event={event} gig={selectedGig} showBack={showBack}>
          <Button variant="contained" color="primary"><Icon>favorite_border</Icon>&nbsp;&nbsp;Geïnteresseerd</Button>
        </EventDetailsCardMedia>
      </Box>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <List disablePadding>
          <ListItem onClick={() => history.push(event?.venue.slug ? `/${event?.venue.slug}` : `/venue/${event?.venue.id}`)} button>
            <ListItemIcon style={{ minWidth: 36 }}><Icon>place</Icon></ListItemIcon>
            <ListItemText primary={<span style={{ whiteSpace: 'nowrap' }}>{event?.venue.name}</span>} secondary={event?.venue.location.address} />
            <ListItemSecondaryAction>
              <IconButton><Icon>chevron_down</Icon></IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon style={{ minWidth: 36 }}><Icon>today</Icon></ListItemIcon>
            <ListItemText primary={<span style={{ whiteSpace: 'nowrap' }}>{moment(selectedGig?.from || event?.from).format('D MMMM YYYY')}</span>} secondary={moment(selectedGig?.from || event?.from).format('HH:mm')} />
          </ListItem>
        </List>
        {(selectedGig?.description || event?.description) && <>
          <Divider />
          <DialogContent sx={{ flex: 'none' }}>
            <Typography>{selectedGig ? selectedGig.description : event?.description}</Typography>
          </DialogContent>
        </>}
        {!selectedGig && <Box flex={1} overflow={isMobile ? 'show' : 'auto'}>
          {gigsByDay?.map(({ date, gigs }, k) => <List disablePadding subheader={<ListSubheader>{gigsByDay.length === 1 ? 'Lineup' : `Lineup ${moment(date).format('dddd D MMMM')}`}</ListSubheader>} key={k}>
            {gigs.map((gig, n) => <ListItem key={n} onClick={() => history.push(`/events/${gig.id}`, location.state)} button>
              <ListItemAvatar>
                <Avatar src={gig.artist.avatar || ''} alt="" />
              </ListItemAvatar>
              <ListItemText primary={gig.artist.name} secondary={`Om: ${moment(gig.from).format('HH:mm')}`} />
              <ListItemSecondaryAction>
                <IconButton color="primary"><Icon>favorite_border</Icon></IconButton>
              </ListItemSecondaryAction>
            </ListItem>)}
          </List>)}
        </Box>}
      </Box>
    </Box>
  </Dialog >
}

export default EventDetails
