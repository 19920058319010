import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AcceptAgreementsInput = {
  agreements: Array<AgreementsInput>;
};

export type Agreements = {
  acceptedOn: Scalars['DateTime'];
  fileName: Scalars['String'];
};

export type AgreementsInput = {
  acceptedOn: Scalars['DateTime'];
  fileName: Scalars['String'];
};

export type Artist = {
  avatar?: Maybe<Scalars['String']>;
  followers?: Maybe<Array<Scalars['String']>>;
  genres: Array<Genre>;
  id: Scalars['String'];
  members: Array<Member>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  type: ProfileType;
};

export type CreateArtistInput = {
  avatar?: Maybe<Scalars['String']>;
  genres: Array<GenreInput>;
  members: Array<MemberInput>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export type CreateEventInput = {
  cover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  gigs: Array<CreateGigInput>;
  price?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  square?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  venueId: Scalars['String'];
};

export type CreateGigInput = {
  artistId: Scalars['String'];
  cover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  from: Scalars['DateTime'];
  square?: Maybe<Scalars['String']>;
  to: Scalars['DateTime'];
};

export type CreateUpdateInput = {
  created: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
};

export type CreateVenueInput = {
  avatar?: Maybe<Scalars['String']>;
  location: LocationInput;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export type Event = {
  cover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  from: Scalars['DateTime'];
  gigs: Array<Gig>;
  id: Scalars['String'];
  owner: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  square?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  to: Scalars['DateTime'];
  venue: Venue;
};

export type Genre = {
  title: Scalars['String'];
};

export type GenreInput = {
  title: Scalars['String'];
};

export type GenreResult = {
  count: Scalars['Float'];
  title: Scalars['String'];
};

export type Gig = {
  artist: Artist;
  cover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  from: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  square?: Maybe<Scalars['String']>;
  to: Scalars['DateTime'];
};

export type Location = {
  address: Scalars['String'];
};

export type LocationInput = {
  address: Scalars['String'];
};

export type Member = {
  instrument?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MemberInput = {
  instrument?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Mutation = {
  acceptAgreements?: Maybe<Scalars['Boolean']>;
  createArtist: Artist;
  createEvent: Event;
  createUpdate: Update;
  createVenue: Venue;
  deleteEvent: Scalars['Boolean'];
  deleteProfile: Scalars['Boolean'];
  deleteUpdate: Scalars['Boolean'];
  toggleFollowArtist: Scalars['Boolean'];
  updateArtist: Scalars['Boolean'];
  updateEvent: Scalars['Boolean'];
  updateOnboardingStatus?: Maybe<Scalars['Boolean']>;
  updateVenue: Scalars['Boolean'];
};


export type MutationAcceptAgreementsArgs = {
  data: AcceptAgreementsInput;
};


export type MutationCreateArtistArgs = {
  data: CreateArtistInput;
};


export type MutationCreateEventArgs = {
  data: CreateEventInput;
};


export type MutationCreateUpdateArgs = {
  data: CreateUpdateInput;
  profileId: Scalars['String'];
};


export type MutationCreateVenueArgs = {
  data: CreateVenueInput;
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['String'];
};


export type MutationDeleteProfileArgs = {
  profileId: Scalars['String'];
};


export type MutationDeleteUpdateArgs = {
  updateId: Scalars['String'];
};


export type MutationToggleFollowArtistArgs = {
  artistId: Scalars['String'];
};


export type MutationUpdateArtistArgs = {
  data: UpdateArtistInput;
};


export type MutationUpdateEventArgs = {
  data: UpdateEventInput;
};


export type MutationUpdateOnboardingStatusArgs = {
  data: Scalars['String'];
};


export type MutationUpdateVenueArgs = {
  data: VenueInput;
};

export enum ProfileType {
  Artist = 'ARTIST',
  Venue = 'VENUE'
}

export type ProfileUnion = Artist | Venue;

export type Query = {
  artists: Array<Artist>;
  event: Event;
  events: Array<Event>;
  findEvents: Array<Event>;
  findEventsByArtist: Array<Event>;
  findEventsByVenue: Array<Event>;
  findEventsByVenueAndDate: Array<Event>;
  findProfile: ProfileUnion;
  genres: Array<GenreResult>;
  me: User;
  searchArtists: Array<Artist>;
  searchVenues: Array<Venue>;
  updates: Array<Update>;
  venues: Array<Venue>;
};


export type QueryEventArgs = {
  eventId: Scalars['String'];
};


export type QueryEventsArgs = {
  page?: Maybe<Scalars['Float']>;
};


export type QueryFindEventsArgs = {
  page?: Maybe<Scalars['Float']>;
  q?: Maybe<Scalars['String']>;
};


export type QueryFindEventsByArtistArgs = {
  artistId: Scalars['String'];
  includeOld?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Float']>;
};


export type QueryFindEventsByVenueArgs = {
  includeOld?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Float']>;
  venueId: Scalars['String'];
};


export type QueryFindEventsByVenueAndDateArgs = {
  date: Scalars['DateTime'];
  venueId: Scalars['String'];
};


export type QueryFindProfileArgs = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<ProfileType>;
};


export type QueryGenresArgs = {
  q?: Maybe<Scalars['String']>;
};


export type QuerySearchArtistsArgs = {
  q: Scalars['String'];
};


export type QuerySearchVenuesArgs = {
  q: Scalars['String'];
};


export type QueryUpdatesArgs = {
  profileId: Scalars['String'];
};

export type Update = {
  created: Scalars['DateTime'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type UpdateArtistInput = {
  avatar?: Maybe<Scalars['String']>;
  genres: Array<GenreInput>;
  id: Scalars['String'];
  members: Array<MemberInput>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  type: ProfileType;
};

export type UpdateEventInput = {
  cover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  featured?: Maybe<Scalars['Boolean']>;
  gigs: Array<UpdateGigInput>;
  id: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  square?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  venueId: Scalars['String'];
};

export type UpdateGigInput = {
  artistId: Scalars['String'];
  cover?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  from: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  square?: Maybe<Scalars['String']>;
  to: Scalars['DateTime'];
};

export type User = {
  agreements: Array<Agreements>;
  onboarding?: Maybe<Scalars['String']>;
  profiles: Array<ProfileUnion>;
};

export type Venue = {
  avatar?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location: Location;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  type: ProfileType;
};

export type VenueInput = {
  avatar?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location: LocationInput;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  type: ProfileType;
};

export type AcceptAgreementsMutationVariables = Exact<{
  data: AcceptAgreementsInput;
}>;


export type AcceptAgreementsMutation = { acceptAgreements?: Maybe<boolean> };

export type CreateArtistMutationVariables = Exact<{
  data: CreateArtistInput;
}>;


export type CreateArtistMutation = { createArtist: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } };

export type CreateEventMutationVariables = Exact<{
  data: CreateEventInput;
}>;


export type CreateEventMutation = { createEvent: { title: string, description?: Maybe<string>, featured?: Maybe<boolean>, price?: Maybe<number>, slug?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id: string, from: any, to: any, owner: string, gigs: Array<{ from: any, to: any, description?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id?: Maybe<string>, owner: string, artist: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } }>, venue: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } } } };

export type CreateUpdateMutationVariables = Exact<{
  profileId: Scalars['String'];
  data: CreateUpdateInput;
}>;


export type CreateUpdateMutation = { createUpdate: { created: any, message?: Maybe<string>, id: string } };

export type CreateVenueMutationVariables = Exact<{
  data: CreateVenueInput;
}>;


export type CreateVenueMutation = { createVenue: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } } };

export type DeleteEventMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type DeleteEventMutation = { deleteEvent: boolean };

export type DeleteProfileMutationVariables = Exact<{
  profileId: Scalars['String'];
}>;


export type DeleteProfileMutation = { deleteProfile: boolean };

export type DeleteUpdateMutationVariables = Exact<{
  updateId: Scalars['String'];
}>;


export type DeleteUpdateMutation = { deleteUpdate: boolean };

export type ToggleFollowArtistMutationVariables = Exact<{
  artistId: Scalars['String'];
}>;


export type ToggleFollowArtistMutation = { toggleFollowArtist: boolean };

export type UpdateArtistMutationVariables = Exact<{
  data: UpdateArtistInput;
}>;


export type UpdateArtistMutation = { updateArtist: boolean };

export type UpdateEventMutationVariables = Exact<{
  data: UpdateEventInput;
}>;


export type UpdateEventMutation = { updateEvent: boolean };

export type UpdateOnboardingStatusMutationVariables = Exact<{
  data: Scalars['String'];
}>;


export type UpdateOnboardingStatusMutation = { updateOnboardingStatus?: Maybe<boolean> };

export type UpdateVenueMutationVariables = Exact<{
  data: VenueInput;
}>;


export type UpdateVenueMutation = { updateVenue: boolean };

export type ArtistsQueryVariables = Exact<{ [key: string]: never; }>;


export type ArtistsQuery = { artists: Array<{ name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> }> };

export type EventQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type EventQuery = { event: { title: string, description?: Maybe<string>, featured?: Maybe<boolean>, price?: Maybe<number>, slug?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id: string, from: any, to: any, owner: string, gigs: Array<{ from: any, to: any, description?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id?: Maybe<string>, owner: string, artist: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } }>, venue: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } } } };

export type EventsQueryVariables = Exact<{
  page?: Maybe<Scalars['Float']>;
}>;


export type EventsQuery = { events: Array<{ title: string, description?: Maybe<string>, featured?: Maybe<boolean>, price?: Maybe<number>, slug?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id: string, from: any, to: any, owner: string, gigs: Array<{ from: any, to: any, description?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id?: Maybe<string>, owner: string, artist: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } }>, venue: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } } }> };

export type FindEventsQueryVariables = Exact<{
  page?: Maybe<Scalars['Float']>;
  q?: Maybe<Scalars['String']>;
}>;


export type FindEventsQuery = { findEvents: Array<{ title: string, description?: Maybe<string>, featured?: Maybe<boolean>, price?: Maybe<number>, slug?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id: string, from: any, to: any, owner: string, gigs: Array<{ from: any, to: any, description?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id?: Maybe<string>, owner: string, artist: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } }>, venue: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } } }> };

export type FindEventsByArtistQueryVariables = Exact<{
  page?: Maybe<Scalars['Float']>;
  includeOld?: Maybe<Scalars['Boolean']>;
  artistId: Scalars['String'];
}>;


export type FindEventsByArtistQuery = { findEventsByArtist: Array<{ title: string, description?: Maybe<string>, featured?: Maybe<boolean>, price?: Maybe<number>, slug?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id: string, from: any, to: any, owner: string, gigs: Array<{ from: any, to: any, description?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id?: Maybe<string>, owner: string, artist: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } }>, venue: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } } }> };

export type FindEventsByVenueQueryVariables = Exact<{
  page?: Maybe<Scalars['Float']>;
  includeOld?: Maybe<Scalars['Boolean']>;
  venueId: Scalars['String'];
}>;


export type FindEventsByVenueQuery = { findEventsByVenue: Array<{ title: string, description?: Maybe<string>, featured?: Maybe<boolean>, price?: Maybe<number>, slug?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id: string, from: any, to: any, owner: string, gigs: Array<{ from: any, to: any, description?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id?: Maybe<string>, owner: string, artist: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } }>, venue: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } } }> };

export type FindEventsByVenueAndDateQueryVariables = Exact<{
  date: Scalars['DateTime'];
  venueId: Scalars['String'];
}>;


export type FindEventsByVenueAndDateQuery = { findEventsByVenueAndDate: Array<{ title: string, description?: Maybe<string>, featured?: Maybe<boolean>, price?: Maybe<number>, slug?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id: string, from: any, to: any, owner: string, gigs: Array<{ from: any, to: any, description?: Maybe<string>, square?: Maybe<string>, cover?: Maybe<string>, id?: Maybe<string>, owner: string, artist: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } }>, venue: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } } }> };

export type FindProfileQueryVariables = Exact<{
  type?: Maybe<ProfileType>;
  id?: Maybe<Scalars['String']>;
}>;


export type FindProfileQuery = { findProfile: { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } | { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } } };

export type GenresQueryVariables = Exact<{
  q?: Maybe<Scalars['String']>;
}>;


export type GenresQuery = { genres: Array<{ title: string, count: number }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { onboarding?: Maybe<string>, agreements: Array<{ fileName: string, acceptedOn: any }>, profiles: Array<{ name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> } | { name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } }> } };

export type SearchArtistsQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type SearchArtistsQuery = { searchArtists: Array<{ name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, followers?: Maybe<Array<string>>, genres: Array<{ title: string }>, members: Array<{ name: string, instrument?: Maybe<string> }> }> };

export type SearchVenuesQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type SearchVenuesQuery = { searchVenues: Array<{ name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } }> };

export type UpdatesQueryVariables = Exact<{
  profileId: Scalars['String'];
}>;


export type UpdatesQuery = { updates: Array<{ created: any, message?: Maybe<string>, id: string }> };

export type VenuesQueryVariables = Exact<{ [key: string]: never; }>;


export type VenuesQuery = { venues: Array<{ name: string, avatar?: Maybe<string>, slug?: Maybe<string>, id: string, type: ProfileType, location: { address: string } }> };


export const AcceptAgreementsMutationDocument = gql`
    mutation acceptAgreementsMutation($data: AcceptAgreementsInput!) {
  acceptAgreements(data: $data)
}
    `;
export type AcceptAgreementsMutationMutationFn = Apollo.MutationFunction<AcceptAgreementsMutation, AcceptAgreementsMutationVariables>;

/**
 * __useAcceptAgreementsMutation__
 *
 * To run a mutation, you first call `useAcceptAgreementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAgreementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAgreementsMutation, { data, loading, error }] = useAcceptAgreementsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAcceptAgreementsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptAgreementsMutation, AcceptAgreementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptAgreementsMutation, AcceptAgreementsMutationVariables>(AcceptAgreementsMutationDocument, options);
      }
export type AcceptAgreementsMutationHookResult = ReturnType<typeof useAcceptAgreementsMutation>;
export type AcceptAgreementsMutationMutationResult = Apollo.MutationResult<AcceptAgreementsMutation>;
export type AcceptAgreementsMutationMutationOptions = Apollo.BaseMutationOptions<AcceptAgreementsMutation, AcceptAgreementsMutationVariables>;
export const CreateArtistMutationDocument = gql`
    mutation createArtistMutation($data: CreateArtistInput!) {
  createArtist(data: $data) {
    name
    avatar
    slug
    id
    type
    genres {
      title
    }
    members {
      name
      instrument
    }
    followers
  }
}
    `;
export type CreateArtistMutationMutationFn = Apollo.MutationFunction<CreateArtistMutation, CreateArtistMutationVariables>;

/**
 * __useCreateArtistMutation__
 *
 * To run a mutation, you first call `useCreateArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtistMutation, { data, loading, error }] = useCreateArtistMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateArtistMutation(baseOptions?: Apollo.MutationHookOptions<CreateArtistMutation, CreateArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArtistMutation, CreateArtistMutationVariables>(CreateArtistMutationDocument, options);
      }
export type CreateArtistMutationHookResult = ReturnType<typeof useCreateArtistMutation>;
export type CreateArtistMutationMutationResult = Apollo.MutationResult<CreateArtistMutation>;
export type CreateArtistMutationMutationOptions = Apollo.BaseMutationOptions<CreateArtistMutation, CreateArtistMutationVariables>;
export const CreateEventMutationDocument = gql`
    mutation createEventMutation($data: CreateEventInput!) {
  createEvent(data: $data) {
    title
    description
    featured
    price
    slug
    square
    cover
    id
    gigs {
      from
      to
      description
      square
      cover
      id
      owner
      artist {
        name
        avatar
        slug
        id
        type
        genres {
          title
        }
        members {
          name
          instrument
        }
        followers
      }
    }
    from
    to
    owner
    venue {
      name
      avatar
      slug
      id
      type
      location {
        address
      }
    }
  }
}
    `;
export type CreateEventMutationMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventMutationDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreateUpdateMutationDocument = gql`
    mutation createUpdateMutation($profileId: String!, $data: CreateUpdateInput!) {
  createUpdate(profileId: $profileId, data: $data) {
    created
    message
    id
  }
}
    `;
export type CreateUpdateMutationMutationFn = Apollo.MutationFunction<CreateUpdateMutation, CreateUpdateMutationVariables>;

/**
 * __useCreateUpdateMutation__
 *
 * To run a mutation, you first call `useCreateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateMutation, { data, loading, error }] = useCreateUpdateMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdateMutation, CreateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdateMutation, CreateUpdateMutationVariables>(CreateUpdateMutationDocument, options);
      }
export type CreateUpdateMutationHookResult = ReturnType<typeof useCreateUpdateMutation>;
export type CreateUpdateMutationMutationResult = Apollo.MutationResult<CreateUpdateMutation>;
export type CreateUpdateMutationMutationOptions = Apollo.BaseMutationOptions<CreateUpdateMutation, CreateUpdateMutationVariables>;
export const CreateVenueMutationDocument = gql`
    mutation createVenueMutation($data: CreateVenueInput!) {
  createVenue(data: $data) {
    name
    avatar
    slug
    id
    type
    location {
      address
    }
  }
}
    `;
export type CreateVenueMutationMutationFn = Apollo.MutationFunction<CreateVenueMutation, CreateVenueMutationVariables>;

/**
 * __useCreateVenueMutation__
 *
 * To run a mutation, you first call `useCreateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVenueMutation, { data, loading, error }] = useCreateVenueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVenueMutation(baseOptions?: Apollo.MutationHookOptions<CreateVenueMutation, CreateVenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVenueMutation, CreateVenueMutationVariables>(CreateVenueMutationDocument, options);
      }
export type CreateVenueMutationHookResult = ReturnType<typeof useCreateVenueMutation>;
export type CreateVenueMutationMutationResult = Apollo.MutationResult<CreateVenueMutation>;
export type CreateVenueMutationMutationOptions = Apollo.BaseMutationOptions<CreateVenueMutation, CreateVenueMutationVariables>;
export const DeleteEventMutationDocument = gql`
    mutation deleteEventMutation($eventId: String!) {
  deleteEvent(eventId: $eventId)
}
    `;
export type DeleteEventMutationMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventMutationDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeleteProfileMutationDocument = gql`
    mutation deleteProfileMutation($profileId: String!) {
  deleteProfile(profileId: $profileId)
}
    `;
export type DeleteProfileMutationMutationFn = Apollo.MutationFunction<DeleteProfileMutation, DeleteProfileMutationVariables>;

/**
 * __useDeleteProfileMutation__
 *
 * To run a mutation, you first call `useDeleteProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileMutation, { data, loading, error }] = useDeleteProfileMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useDeleteProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfileMutation, DeleteProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfileMutation, DeleteProfileMutationVariables>(DeleteProfileMutationDocument, options);
      }
export type DeleteProfileMutationHookResult = ReturnType<typeof useDeleteProfileMutation>;
export type DeleteProfileMutationMutationResult = Apollo.MutationResult<DeleteProfileMutation>;
export type DeleteProfileMutationMutationOptions = Apollo.BaseMutationOptions<DeleteProfileMutation, DeleteProfileMutationVariables>;
export const DeleteUpdateMutationDocument = gql`
    mutation deleteUpdateMutation($updateId: String!) {
  deleteUpdate(updateId: $updateId)
}
    `;
export type DeleteUpdateMutationMutationFn = Apollo.MutationFunction<DeleteUpdateMutation, DeleteUpdateMutationVariables>;

/**
 * __useDeleteUpdateMutation__
 *
 * To run a mutation, you first call `useDeleteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUpdateMutation, { data, loading, error }] = useDeleteUpdateMutation({
 *   variables: {
 *      updateId: // value for 'updateId'
 *   },
 * });
 */
export function useDeleteUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUpdateMutation, DeleteUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUpdateMutation, DeleteUpdateMutationVariables>(DeleteUpdateMutationDocument, options);
      }
export type DeleteUpdateMutationHookResult = ReturnType<typeof useDeleteUpdateMutation>;
export type DeleteUpdateMutationMutationResult = Apollo.MutationResult<DeleteUpdateMutation>;
export type DeleteUpdateMutationMutationOptions = Apollo.BaseMutationOptions<DeleteUpdateMutation, DeleteUpdateMutationVariables>;
export const ToggleFollowArtistMutationDocument = gql`
    mutation toggleFollowArtistMutation($artistId: String!) {
  toggleFollowArtist(artistId: $artistId)
}
    `;
export type ToggleFollowArtistMutationMutationFn = Apollo.MutationFunction<ToggleFollowArtistMutation, ToggleFollowArtistMutationVariables>;

/**
 * __useToggleFollowArtistMutation__
 *
 * To run a mutation, you first call `useToggleFollowArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFollowArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFollowArtistMutation, { data, loading, error }] = useToggleFollowArtistMutation({
 *   variables: {
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useToggleFollowArtistMutation(baseOptions?: Apollo.MutationHookOptions<ToggleFollowArtistMutation, ToggleFollowArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleFollowArtistMutation, ToggleFollowArtistMutationVariables>(ToggleFollowArtistMutationDocument, options);
      }
export type ToggleFollowArtistMutationHookResult = ReturnType<typeof useToggleFollowArtistMutation>;
export type ToggleFollowArtistMutationMutationResult = Apollo.MutationResult<ToggleFollowArtistMutation>;
export type ToggleFollowArtistMutationMutationOptions = Apollo.BaseMutationOptions<ToggleFollowArtistMutation, ToggleFollowArtistMutationVariables>;
export const UpdateArtistMutationDocument = gql`
    mutation updateArtistMutation($data: UpdateArtistInput!) {
  updateArtist(data: $data)
}
    `;
export type UpdateArtistMutationMutationFn = Apollo.MutationFunction<UpdateArtistMutation, UpdateArtistMutationVariables>;

/**
 * __useUpdateArtistMutation__
 *
 * To run a mutation, you first call `useUpdateArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtistMutation, { data, loading, error }] = useUpdateArtistMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateArtistMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArtistMutation, UpdateArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArtistMutation, UpdateArtistMutationVariables>(UpdateArtistMutationDocument, options);
      }
export type UpdateArtistMutationHookResult = ReturnType<typeof useUpdateArtistMutation>;
export type UpdateArtistMutationMutationResult = Apollo.MutationResult<UpdateArtistMutation>;
export type UpdateArtistMutationMutationOptions = Apollo.BaseMutationOptions<UpdateArtistMutation, UpdateArtistMutationVariables>;
export const UpdateEventMutationDocument = gql`
    mutation updateEventMutation($data: UpdateEventInput!) {
  updateEvent(data: $data)
}
    `;
export type UpdateEventMutationMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventMutationDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const UpdateOnboardingStatusMutationDocument = gql`
    mutation updateOnboardingStatusMutation($data: String!) {
  updateOnboardingStatus(data: $data)
}
    `;
export type UpdateOnboardingStatusMutationMutationFn = Apollo.MutationFunction<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>;

/**
 * __useUpdateOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingStatusMutation, { data, loading, error }] = useUpdateOnboardingStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOnboardingStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>(UpdateOnboardingStatusMutationDocument, options);
      }
export type UpdateOnboardingStatusMutationHookResult = ReturnType<typeof useUpdateOnboardingStatusMutation>;
export type UpdateOnboardingStatusMutationMutationResult = Apollo.MutationResult<UpdateOnboardingStatusMutation>;
export type UpdateOnboardingStatusMutationMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>;
export const UpdateVenueMutationDocument = gql`
    mutation updateVenueMutation($data: VenueInput!) {
  updateVenue(data: $data)
}
    `;
export type UpdateVenueMutationMutationFn = Apollo.MutationFunction<UpdateVenueMutation, UpdateVenueMutationVariables>;

/**
 * __useUpdateVenueMutation__
 *
 * To run a mutation, you first call `useUpdateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueMutation, { data, loading, error }] = useUpdateVenueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVenueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVenueMutation, UpdateVenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVenueMutation, UpdateVenueMutationVariables>(UpdateVenueMutationDocument, options);
      }
export type UpdateVenueMutationHookResult = ReturnType<typeof useUpdateVenueMutation>;
export type UpdateVenueMutationMutationResult = Apollo.MutationResult<UpdateVenueMutation>;
export type UpdateVenueMutationMutationOptions = Apollo.BaseMutationOptions<UpdateVenueMutation, UpdateVenueMutationVariables>;
export const ArtistsQueryDocument = gql`
    query artistsQuery {
  artists {
    name
    avatar
    slug
    id
    type
    genres {
      title
    }
    members {
      name
      instrument
    }
    followers
  }
}
    `;

/**
 * __useArtistsQuery__
 *
 * To run a query within a React component, call `useArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useArtistsQuery(baseOptions?: Apollo.QueryHookOptions<ArtistsQuery, ArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArtistsQuery, ArtistsQueryVariables>(ArtistsQueryDocument, options);
      }
export function useArtistsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArtistsQuery, ArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArtistsQuery, ArtistsQueryVariables>(ArtistsQueryDocument, options);
        }
export type ArtistsQueryHookResult = ReturnType<typeof useArtistsQuery>;
export type ArtistsQueryLazyQueryHookResult = ReturnType<typeof useArtistsQueryLazyQuery>;
export type ArtistsQueryQueryResult = Apollo.QueryResult<ArtistsQuery, ArtistsQueryVariables>;
export const EventQueryDocument = gql`
    query eventQuery($eventId: String!) {
  event(eventId: $eventId) {
    title
    description
    featured
    price
    slug
    square
    cover
    id
    gigs {
      from
      to
      description
      square
      cover
      id
      owner
      artist {
        name
        avatar
        slug
        id
        type
        genres {
          title
        }
        members {
          name
          instrument
        }
        followers
      }
    }
    from
    to
    owner
    venue {
      name
      avatar
      slug
      id
      type
      location {
        address
      }
    }
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventQueryDocument, options);
      }
export function useEventQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventQueryDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventQueryLazyQueryHookResult = ReturnType<typeof useEventQueryLazyQuery>;
export type EventQueryQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventsQueryDocument = gql`
    query eventsQuery($page: Float) {
  events(page: $page) {
    title
    description
    featured
    price
    slug
    square
    cover
    id
    gigs {
      from
      to
      description
      square
      cover
      id
      owner
      artist {
        name
        avatar
        slug
        id
        type
        genres {
          title
        }
        members {
          name
          instrument
        }
        followers
      }
    }
    from
    to
    owner
    venue {
      name
      avatar
      slug
      id
      type
      location {
        address
      }
    }
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsQueryDocument, options);
      }
export function useEventsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsQueryDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsQueryLazyQueryHookResult = ReturnType<typeof useEventsQueryLazyQuery>;
export type EventsQueryQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const FindEventsQueryDocument = gql`
    query findEventsQuery($page: Float, $q: String) {
  findEvents(page: $page, q: $q) {
    title
    description
    featured
    price
    slug
    square
    cover
    id
    gigs {
      from
      to
      description
      square
      cover
      id
      owner
      artist {
        name
        avatar
        slug
        id
        type
        genres {
          title
        }
        members {
          name
          instrument
        }
        followers
      }
    }
    from
    to
    owner
    venue {
      name
      avatar
      slug
      id
      type
      location {
        address
      }
    }
  }
}
    `;

/**
 * __useFindEventsQuery__
 *
 * To run a query within a React component, call `useFindEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useFindEventsQuery(baseOptions?: Apollo.QueryHookOptions<FindEventsQuery, FindEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEventsQuery, FindEventsQueryVariables>(FindEventsQueryDocument, options);
      }
export function useFindEventsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEventsQuery, FindEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEventsQuery, FindEventsQueryVariables>(FindEventsQueryDocument, options);
        }
export type FindEventsQueryHookResult = ReturnType<typeof useFindEventsQuery>;
export type FindEventsQueryLazyQueryHookResult = ReturnType<typeof useFindEventsQueryLazyQuery>;
export type FindEventsQueryQueryResult = Apollo.QueryResult<FindEventsQuery, FindEventsQueryVariables>;
export const FindEventsByArtistQueryDocument = gql`
    query findEventsByArtistQuery($page: Float, $includeOld: Boolean, $artistId: String!) {
  findEventsByArtist(page: $page, includeOld: $includeOld, artistId: $artistId) {
    title
    description
    featured
    price
    slug
    square
    cover
    id
    gigs {
      from
      to
      description
      square
      cover
      id
      owner
      artist {
        name
        avatar
        slug
        id
        type
        genres {
          title
        }
        members {
          name
          instrument
        }
        followers
      }
    }
    from
    to
    owner
    venue {
      name
      avatar
      slug
      id
      type
      location {
        address
      }
    }
  }
}
    `;

/**
 * __useFindEventsByArtistQuery__
 *
 * To run a query within a React component, call `useFindEventsByArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventsByArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventsByArtistQuery({
 *   variables: {
 *      page: // value for 'page'
 *      includeOld: // value for 'includeOld'
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useFindEventsByArtistQuery(baseOptions: Apollo.QueryHookOptions<FindEventsByArtistQuery, FindEventsByArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEventsByArtistQuery, FindEventsByArtistQueryVariables>(FindEventsByArtistQueryDocument, options);
      }
export function useFindEventsByArtistQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEventsByArtistQuery, FindEventsByArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEventsByArtistQuery, FindEventsByArtistQueryVariables>(FindEventsByArtistQueryDocument, options);
        }
export type FindEventsByArtistQueryHookResult = ReturnType<typeof useFindEventsByArtistQuery>;
export type FindEventsByArtistQueryLazyQueryHookResult = ReturnType<typeof useFindEventsByArtistQueryLazyQuery>;
export type FindEventsByArtistQueryQueryResult = Apollo.QueryResult<FindEventsByArtistQuery, FindEventsByArtistQueryVariables>;
export const FindEventsByVenueQueryDocument = gql`
    query findEventsByVenueQuery($page: Float, $includeOld: Boolean, $venueId: String!) {
  findEventsByVenue(page: $page, includeOld: $includeOld, venueId: $venueId) {
    title
    description
    featured
    price
    slug
    square
    cover
    id
    gigs {
      from
      to
      description
      square
      cover
      id
      owner
      artist {
        name
        avatar
        slug
        id
        type
        genres {
          title
        }
        members {
          name
          instrument
        }
        followers
      }
    }
    from
    to
    owner
    venue {
      name
      avatar
      slug
      id
      type
      location {
        address
      }
    }
  }
}
    `;

/**
 * __useFindEventsByVenueQuery__
 *
 * To run a query within a React component, call `useFindEventsByVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventsByVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventsByVenueQuery({
 *   variables: {
 *      page: // value for 'page'
 *      includeOld: // value for 'includeOld'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useFindEventsByVenueQuery(baseOptions: Apollo.QueryHookOptions<FindEventsByVenueQuery, FindEventsByVenueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEventsByVenueQuery, FindEventsByVenueQueryVariables>(FindEventsByVenueQueryDocument, options);
      }
export function useFindEventsByVenueQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEventsByVenueQuery, FindEventsByVenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEventsByVenueQuery, FindEventsByVenueQueryVariables>(FindEventsByVenueQueryDocument, options);
        }
export type FindEventsByVenueQueryHookResult = ReturnType<typeof useFindEventsByVenueQuery>;
export type FindEventsByVenueQueryLazyQueryHookResult = ReturnType<typeof useFindEventsByVenueQueryLazyQuery>;
export type FindEventsByVenueQueryQueryResult = Apollo.QueryResult<FindEventsByVenueQuery, FindEventsByVenueQueryVariables>;
export const FindEventsByVenueAndDateQueryDocument = gql`
    query findEventsByVenueAndDateQuery($date: DateTime!, $venueId: String!) {
  findEventsByVenueAndDate(date: $date, venueId: $venueId) {
    title
    description
    featured
    price
    slug
    square
    cover
    id
    gigs {
      from
      to
      description
      square
      cover
      id
      owner
      artist {
        name
        avatar
        slug
        id
        type
        genres {
          title
        }
        members {
          name
          instrument
        }
        followers
      }
    }
    from
    to
    owner
    venue {
      name
      avatar
      slug
      id
      type
      location {
        address
      }
    }
  }
}
    `;

/**
 * __useFindEventsByVenueAndDateQuery__
 *
 * To run a query within a React component, call `useFindEventsByVenueAndDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventsByVenueAndDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventsByVenueAndDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useFindEventsByVenueAndDateQuery(baseOptions: Apollo.QueryHookOptions<FindEventsByVenueAndDateQuery, FindEventsByVenueAndDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEventsByVenueAndDateQuery, FindEventsByVenueAndDateQueryVariables>(FindEventsByVenueAndDateQueryDocument, options);
      }
export function useFindEventsByVenueAndDateQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEventsByVenueAndDateQuery, FindEventsByVenueAndDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEventsByVenueAndDateQuery, FindEventsByVenueAndDateQueryVariables>(FindEventsByVenueAndDateQueryDocument, options);
        }
export type FindEventsByVenueAndDateQueryHookResult = ReturnType<typeof useFindEventsByVenueAndDateQuery>;
export type FindEventsByVenueAndDateQueryLazyQueryHookResult = ReturnType<typeof useFindEventsByVenueAndDateQueryLazyQuery>;
export type FindEventsByVenueAndDateQueryQueryResult = Apollo.QueryResult<FindEventsByVenueAndDateQuery, FindEventsByVenueAndDateQueryVariables>;
export const FindProfileQueryDocument = gql`
    query findProfileQuery($type: ProfileType, $id: String) {
  findProfile(type: $type, id: $id) {
    ... on Artist {
      name
      avatar
      slug
      id
      type
      genres {
        title
      }
      members {
        name
        instrument
      }
      followers
    }
    ... on Venue {
      name
      avatar
      slug
      id
      type
      location {
        address
      }
    }
  }
}
    `;

/**
 * __useFindProfileQuery__
 *
 * To run a query within a React component, call `useFindProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProfileQuery({
 *   variables: {
 *      type: // value for 'type'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindProfileQuery(baseOptions?: Apollo.QueryHookOptions<FindProfileQuery, FindProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindProfileQuery, FindProfileQueryVariables>(FindProfileQueryDocument, options);
      }
export function useFindProfileQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindProfileQuery, FindProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindProfileQuery, FindProfileQueryVariables>(FindProfileQueryDocument, options);
        }
export type FindProfileQueryHookResult = ReturnType<typeof useFindProfileQuery>;
export type FindProfileQueryLazyQueryHookResult = ReturnType<typeof useFindProfileQueryLazyQuery>;
export type FindProfileQueryQueryResult = Apollo.QueryResult<FindProfileQuery, FindProfileQueryVariables>;
export const GenresQueryDocument = gql`
    query genresQuery($q: String) {
  genres(q: $q) {
    title
    count
  }
}
    `;

/**
 * __useGenresQuery__
 *
 * To run a query within a React component, call `useGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenresQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useGenresQuery(baseOptions?: Apollo.QueryHookOptions<GenresQuery, GenresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenresQuery, GenresQueryVariables>(GenresQueryDocument, options);
      }
export function useGenresQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenresQuery, GenresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenresQuery, GenresQueryVariables>(GenresQueryDocument, options);
        }
export type GenresQueryHookResult = ReturnType<typeof useGenresQuery>;
export type GenresQueryLazyQueryHookResult = ReturnType<typeof useGenresQueryLazyQuery>;
export type GenresQueryQueryResult = Apollo.QueryResult<GenresQuery, GenresQueryVariables>;
export const MeQueryDocument = gql`
    query meQuery {
  me {
    onboarding
    agreements {
      fileName
      acceptedOn
    }
    profiles {
      ... on Artist {
        name
        avatar
        slug
        id
        type
        genres {
          title
        }
        members {
          name
          instrument
        }
        followers
      }
      ... on Venue {
        name
        avatar
        slug
        id
        type
        location {
          address
        }
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeQueryDocument, options);
      }
export function useMeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeQueryDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeQueryLazyQueryHookResult = ReturnType<typeof useMeQueryLazyQuery>;
export type MeQueryQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SearchArtistsQueryDocument = gql`
    query searchArtistsQuery($q: String!) {
  searchArtists(q: $q) {
    name
    avatar
    slug
    id
    type
    genres {
      title
    }
    members {
      name
      instrument
    }
    followers
  }
}
    `;

/**
 * __useSearchArtistsQuery__
 *
 * To run a query within a React component, call `useSearchArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchArtistsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchArtistsQuery(baseOptions: Apollo.QueryHookOptions<SearchArtistsQuery, SearchArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchArtistsQuery, SearchArtistsQueryVariables>(SearchArtistsQueryDocument, options);
      }
export function useSearchArtistsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchArtistsQuery, SearchArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchArtistsQuery, SearchArtistsQueryVariables>(SearchArtistsQueryDocument, options);
        }
export type SearchArtistsQueryHookResult = ReturnType<typeof useSearchArtistsQuery>;
export type SearchArtistsQueryLazyQueryHookResult = ReturnType<typeof useSearchArtistsQueryLazyQuery>;
export type SearchArtistsQueryQueryResult = Apollo.QueryResult<SearchArtistsQuery, SearchArtistsQueryVariables>;
export const SearchVenuesQueryDocument = gql`
    query searchVenuesQuery($q: String!) {
  searchVenues(q: $q) {
    name
    avatar
    slug
    id
    type
    location {
      address
    }
  }
}
    `;

/**
 * __useSearchVenuesQuery__
 *
 * To run a query within a React component, call `useSearchVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVenuesQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchVenuesQuery(baseOptions: Apollo.QueryHookOptions<SearchVenuesQuery, SearchVenuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchVenuesQuery, SearchVenuesQueryVariables>(SearchVenuesQueryDocument, options);
      }
export function useSearchVenuesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchVenuesQuery, SearchVenuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchVenuesQuery, SearchVenuesQueryVariables>(SearchVenuesQueryDocument, options);
        }
export type SearchVenuesQueryHookResult = ReturnType<typeof useSearchVenuesQuery>;
export type SearchVenuesQueryLazyQueryHookResult = ReturnType<typeof useSearchVenuesQueryLazyQuery>;
export type SearchVenuesQueryQueryResult = Apollo.QueryResult<SearchVenuesQuery, SearchVenuesQueryVariables>;
export const UpdatesQueryDocument = gql`
    query updatesQuery($profileId: String!) {
  updates(profileId: $profileId) {
    created
    message
    id
  }
}
    `;

/**
 * __useUpdatesQuery__
 *
 * To run a query within a React component, call `useUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatesQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useUpdatesQuery(baseOptions: Apollo.QueryHookOptions<UpdatesQuery, UpdatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdatesQuery, UpdatesQueryVariables>(UpdatesQueryDocument, options);
      }
export function useUpdatesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdatesQuery, UpdatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdatesQuery, UpdatesQueryVariables>(UpdatesQueryDocument, options);
        }
export type UpdatesQueryHookResult = ReturnType<typeof useUpdatesQuery>;
export type UpdatesQueryLazyQueryHookResult = ReturnType<typeof useUpdatesQueryLazyQuery>;
export type UpdatesQueryQueryResult = Apollo.QueryResult<UpdatesQuery, UpdatesQueryVariables>;
export const VenuesQueryDocument = gql`
    query venuesQuery {
  venues {
    name
    avatar
    slug
    id
    type
    location {
      address
    }
  }
}
    `;

/**
 * __useVenuesQuery__
 *
 * To run a query within a React component, call `useVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVenuesQuery(baseOptions?: Apollo.QueryHookOptions<VenuesQuery, VenuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VenuesQuery, VenuesQueryVariables>(VenuesQueryDocument, options);
      }
export function useVenuesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VenuesQuery, VenuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VenuesQuery, VenuesQueryVariables>(VenuesQueryDocument, options);
        }
export type VenuesQueryHookResult = ReturnType<typeof useVenuesQuery>;
export type VenuesQueryLazyQueryHookResult = ReturnType<typeof useVenuesQueryLazyQuery>;
export type VenuesQueryQueryResult = Apollo.QueryResult<VenuesQuery, VenuesQueryVariables>;