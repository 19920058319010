import { useCallback, useState } from 'react'
import { Welcome, Policies } from '.'
import { Timestamp } from 'firebase/firestore'
import { Box } from '@mui/material'
import { AppBar } from '../common'
import { FullMetadata } from 'firebase/storage'

type Policy = FullMetadata & { content: string }

type OnboardingProps = {
  isNewUser: boolean,
  policies: Policy[],
  onPoliciesAccepted: (accepted: { [key: string]: Timestamp }) => void
}

function Onboarding({ isNewUser, policies, onPoliciesAccepted }: OnboardingProps) {
  const [step, setStep] = useState<'welcome' | 'agreements'>('welcome')

  const renderOnboardingStep = useCallback(() => {
    switch (step) {
      case 'welcome': return <Welcome isNewUser={isNewUser} onContinue={() => setStep('agreements')} />
      case 'agreements': return <Policies policies={policies} onAccept={onPoliciesAccepted} />
    }
  }, [step, isNewUser, policies, onPoliciesAccepted])

  return <Box sx={{ height: '100%', width: '100%', overflow: 'auto', background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(\'./img/concert_001.jpg\')', backgroundPosition: 'center', backgroundSize: 'cover' }}>
    <AppBar color="default" hideLogin />
    {renderOnboardingStep()}
  </Box>
}

export default Onboarding
