import { Components } from '@mui/material'
import { theme } from '../../config/theme'

const MuiAppBar: Components['MuiAppBar'] = {
  styleOverrides: {
    colorDefault: {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    },
    colorPrimary: {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    }
  }
}

export default MuiAppBar
