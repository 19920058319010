import { Box, Typography } from '@mui/material'
import { CenteredPage } from '../common'

function EmailSent() {
  const email = localStorage.getItem('emailForSignIn')
  return <Box height="100%" overflow="hidden">
    <CenteredPage overflow="auto">
      <Typography variant="h2" gutterBottom>E-mail verstuurd!</Typography>
      <Typography variant="subtitle1" component="p" align="center" gutterBottom style={{ maxWidth: 580 }}>We hebben een e-mail gestuurd naar <Typography component="span" color="primary">{email}</Typography>, met daarin een link om je account te verifi&euml;ren.</Typography>
    </CenteredPage>
  </Box>
}

export default EmailSent
