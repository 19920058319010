import { useRef, useMemo, useEffect } from 'react'
import { Avatar, Box, Card, List, ListItem, ListItemAvatar, ListItemText, useMediaQuery, useTheme } from '@mui/material'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Masonry, { MasonryOptions } from 'react-masonry-component'
import { FeaturedEventCard, EventCard } from '.'
import { useFindEventsQuery, useSearchArtistsQuery } from '../../graphql'


const masonryOptions: MasonryOptions = {
  transitionDuration: 0,
  itemSelector: '.masonry-item',
  stamp: '.masonry-stamp'
}

function match(value: string, lowerCaseSearch: string) {
  return value.toLowerCase().indexOf(lowerCaseSearch) >= 0
}

function EventsOverview() {
  const history = useHistory()
  const { pathname } = useLocation()
  const { search } = useParams<{ search?: string }>()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))
  const masonryRef = useRef<any>(null)
  const { data: { findEvents: events } = { findEvents: [] } } = useFindEventsQuery(search ? { variables: { q: search.toLowerCase() } } : undefined)
  const { data: { searchArtists: artistsResults } = { searchArtists: [] } } = useSearchArtistsQuery({ variables: { q: search?.toLowerCase() || '' } })

  const filteredEvents = useMemo(() => {
    if (search) {
      const lowerCaseSearch = search.toLowerCase()
      return events.filter(({ title, gigs }) => match(title, lowerCaseSearch) || gigs.find(g => match(g.artist.name, lowerCaseSearch)) ? true : false)
    } else {
      return events
    }
  }, [search, events])

  useEffect(() => {
    masonryRef.current?.masonry.layout()
  }, [pathname])

  return <Box display="flex" justifyContent="center" padding={1}>
    <Masonry options={masonryOptions} ref={masonryRef} style={{ width: '100%' }}>
      {artistsResults.map(({ slug, id, name, avatar }, k) => <Box width={xl ? '25%' : lg ? '33.3%' : md ? '50%' : sm ? '50%' : '100%'} key={k} className="masonry-item">
        <Card style={{ margin: 8 }}>
          <List disablePadding>
            <ListItem onClick={() => history.push(slug ? `/${slug}` : `/artist/${id}`)} button>
              <ListItemAvatar><Avatar src={avatar || ''} alt={name} /></ListItemAvatar>
              <ListItemText primary={name} />
            </ListItem>
          </List>
        </Card>
      </Box>)}
      {filteredEvents.map(({ featured, ...event }, k) => <Box width={xl ? '25%' : lg ? '33.3%' : md ? '50%' : sm ? '50%' : '100%'} key={k} className="masonry-item">
        {featured ? <FeaturedEventCard event={event} /> : <EventCard event={event} />}
      </Box>)}
    </Masonry>
  </Box>
}

export default EventsOverview
