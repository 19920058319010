import { Box, useTheme, useMediaQuery } from '@mui/material'
import Masonry, { MasonryOptions } from 'react-masonry-component'
import { useCallback, useMemo, useRef } from 'react'
import { Artist, ProfileType, useFindEventsByArtistQuery, useFindEventsByVenueQuery, useUpdatesQuery, Venue } from '../../graphql'
import { ManageContentCard } from '.'

const masonryOptions: MasonryOptions = {
  transitionDuration: 0,
  itemSelector: '.masonry-item',
  stamp: '.masonry-stamp'
}

function useFindEventsByProfile(profile?: Artist | Venue) {
  const { data: { findEventsByArtist } = { findEventsByArtist: [] } } = useFindEventsByArtistQuery({ variables: { artistId: profile?.id || '' } })
  const { data: { findEventsByVenue } = { findEventsByVenue: [] } } = useFindEventsByVenueQuery({ variables: { venueId: profile?.id || '' } })
  return profile?.type === ProfileType.Artist ? findEventsByArtist : findEventsByVenue
}

function ManageContent({ profile }: { profile?: Artist | Venue }) {
  const events = useFindEventsByProfile(profile)
  const { data: { updates } = { updates: [] } } = useUpdatesQuery({ variables: { profileId: profile?.id || '' } })
  const masonryRef = useRef<any>(null)
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))
  const sortEventsAndUpdates = useCallback((a: { created: Date }, b: { created: Date }) => a.created > b.created ? 1 : b.created > a.created ? -1 : 0, [])
  const eventsAndUpdates = useMemo(() => [
    ...updates.map(u => ({ ...u, type: 'update' as 'update' })),
    ...events.map(e => ({
      ...e,
      type: 'event' as 'event',
      created: e.from
    }))].sort(sortEventsAndUpdates), [events, updates, sortEventsAndUpdates])
  return <Masonry options={masonryOptions} ref={masonryRef} style={{ width: '100%' }}>
    {eventsAndUpdates.map((item, k) => <Box width={xl ? '33.3%' : lg ? '50%' : md ? '50%' : sm ? '100%' : '100%'} key={k} className="masonry-item">
      <ManageContentCard item={item} profileId={profile?.id || ''} key={k} />
    </Box>)}
  </Masonry>
}

export default ManageContent
