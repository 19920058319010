import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, GoogleAuthProvider, User as FirebaseUser } from 'firebase/auth'
import { Onboarding } from '.'
import { Loading } from '../common'
import { User } from '../../graphql'
import { Timestamp } from 'firebase/firestore'
import moment from 'moment'
import { useAtom } from 'jotai'
import { policiesAtom } from '../../atoms'
import { AgreementsInput, useAcceptAgreementsMutation, useMeQueryLazyQuery } from '../../graphql'
import { useUpdateOnboardingStatusMutation } from '../../graphql'
import { FullMetadata } from 'firebase/storage'

type Policy = FullMetadata & { content: string }

export const EMAIL_FOR_SIGN_IN_KEY = 'emailForSignIn'

export const providers = {
  google: new GoogleAuthProvider()
}

export type AuthUser = User & FirebaseUser

const AuthContext = React.createContext<{ user: AuthUser | null, logout: () => void }>({ user: null, logout: () => { } })

export function useAuth() {
  return useContext(AuthContext)
}

/*
function useOriginalPathname() {
  const { pathname } = useLocation()
  const originalPath = useRef<string>()
  useEffect(() => {
    if (!originalPath.current) {
      originalPath.current = pathname
    }
  }, [pathname])
  return originalPath.current
}
*/

type AuthUserState = {
  user: AuthUser | null,
  missingAgreements: Policy[]
}

const initialState: AuthUserState = {
  user: null,
  missingAgreements: []
}

function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const auth = useMemo(() => getAuth(), [])
  const [{ user, missingAgreements }, setAuthUserState] = useState<AuthUserState>(initialState)
  const [loading, setLoading] = useState(true)
  const [getMe, { loading: loadingMe, data: { me } = { me: undefined } }] = useMeQueryLazyQuery({ fetchPolicy: 'no-cache' })
  const status = useMemo(() => loading || loadingMe ? 'loading' : missingAgreements.length > 0 ? 'onboarding' : 'complete', [loading, loadingMe, missingAgreements]) // TODO: dont do onboarding this way... use proper routing...
  const [policies] = useAtom(policiesAtom)
  const [acceptAgreements] = useAcceptAgreementsMutation()
  const [updateOnboardingStatus] = useUpdateOnboardingStatusMutation()
  // const originalPath = useOriginalPathname()

  useEffect(() => {
    auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        getMe()
      } else {
        setAuthUserState(initialState)
      }
      setLoading(false)
    })
  }, [auth, getMe])

  useEffect(() => {
    if (me && policies) {
      const filtered = me.agreements ? policies.filter(({ name, updated }) => {
        const agreement = me.agreements.find(({ fileName }: any) => fileName === name)
        return !agreement || !moment(new Date(updated)).isSame(new Date(agreement.acceptedOn), 'seconds')
      }) : policies
      Promise.all(filtered.map(async ({ downloadURL, ...f }) => {
        const content = await (await fetch(downloadURL)).text()
        return { ...f, content }
      })).then((missingAgreements) => {
        setAuthUserState(s => ({ ...s, user: { ...auth.currentUser as FirebaseUser, ...me }, missingAgreements, isNewUser: !me.agreements ? true : false }))
      })
    }
  }, [me, policies, auth])

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem(EMAIL_FOR_SIGN_IN_KEY)
      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }
      signInWithEmailLink(auth, email || '', window.location.href)
        .then((result) => {
          window.localStorage.removeItem(EMAIL_FOR_SIGN_IN_KEY)
        })
    }
  }, [auth])

  const handlePoliciesAccepted = useCallback((agreements: { [key: string]: Timestamp }) => {
    acceptAgreements({ variables: { data: { agreements: Object.keys(agreements).map(k => ({ acceptedOn: agreements[k].toDate(), fileName: k })) as AgreementsInput[] } } })
    me?.onboarding === 'new' && updateOnboardingStatus({ variables: { data: 'done' } })
    setAuthUserState(s => ({ ...s, missingAgreements: [] }))
  }, [acceptAgreements, updateOnboardingStatus, me?.onboarding])

  const logout = useCallback(async () => {
    await auth.signOut()
    setAuthUserState(initialState)
  }, [auth])

  const renderChildren = useCallback(() => {
    switch (status) {
      case 'loading': return <Loading />
      case 'onboarding': return <Onboarding isNewUser={me?.onboarding === 'new'} policies={missingAgreements} onPoliciesAccepted={handlePoliciesAccepted} />
      default: return children
    }
  }, [status, missingAgreements, me?.onboarding, children, handlePoliciesAccepted])

  return <AuthContext.Provider value={{ user, logout }}>{renderChildren()}</AuthContext.Provider>
}

export default AuthProvider
