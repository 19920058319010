import { Box } from '@mui/material'
import { CenteredPage } from '../common'
import { SignupForm } from '.'

function Login() {
  return <Box height="100%" overflow="hidden">
    <CenteredPage overflow="auto">
      <SignupForm
        buttonText="Inloggen"
        socialButtonText="Inloggen met"
      />
    </CenteredPage>
  </Box>
}

export default Login
