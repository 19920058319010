import { CenteredPage } from '../common'
import { SignupForm } from '.'
import { Box, Typography } from '@mui/material'

function Signup() {
  return <Box height="100%" overflow="hidden">
    <CenteredPage overflow="auto">
      <Typography variant="h2" gutterBottom>Join our online music community!</Typography>
      <Typography variant="subtitle1" component="p" align="center" gutterBottom style={{ maxWidth: 580 }}>Met jouw Band-Base account kun je jouw band of podium toevoegen aan onze groeiende database, of help je favoriete artiesten op ons platform, om meer en betere optredens te krijgen!</Typography>
      <SignupForm
        buttonText="Aanmelden"
        socialButtonText="Aanmelden met"
        content={<Typography align="center" gutterBottom style={{ maxWidth: 280 }}>We hebben alleen je e-mailadres nodig om te beginnen!</Typography>}
      />
    </CenteredPage>
  </Box>
}

export default Signup
