import { Button, Box, BoxProps, ButtonProps } from '@mui/material'
import GoogleIcon from '../../assets/icons/GoogleIcon'
// import FacebookIcon from '../../assets/icons/FacebookIcon'

export type SocialProviders = 'google'

function SocialSignin({ text = 'Aanmelden met', onClick, ButtonProps, ...props }: Omit<BoxProps, 'onClick'> & { text?: string, onClick: (provider: SocialProviders) => void | Promise<void>, ButtonProps?: ButtonProps }) {
  return <Box display="flex" {...props}>
    <Button size="small" onClick={() => onClick('google')} color="primary" {...ButtonProps}>
      <GoogleIcon />&nbsp;
      {text} Google
    </Button>{/*&nbsp;&nbsp;*/}
    {/*<Button size="small" color="primary" {...ButtonProps}>
      <FacebookIcon />&nbsp;
      {text} Facebook
    </Button>*/}
  </Box>
}

export default SocialSignin
