import { useState, useEffect } from 'react'
//import { createFilterOptions } from '@mui/core'
import { Autocomplete, AutocompleteRenderInputParams, Button, Card, CardActions, CardHeader, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Step, StepContent, StepLabel, Stepper, TextField, Typography } from '@mui/material'

//const filter = createFilterOptions()

function OnboardingCard({ onLayoutChange }: { onLayoutChange: () => void }) {
  const [step, setStep] = useState(0)
  const [type, setType] = useState<'fan' | 'artist' | 'venue' | ''>('')

  useEffect(() => {
    const interval = setInterval(() => {
      onLayoutChange()
    }, 10)
    setTimeout(() => {
      clearInterval(interval)
    }, 300)
  }, [step, onLayoutChange])

  /*
  const handleSubmit = useCallback(() => {
    console.log('submitted')
  }, [])
  */

  return <Card style={{ margin: 8 }}>
    <CardHeader title="Welkom bij Band-Base!" />
    <Stepper orientation="vertical" activeStep={step}>
      <Step>
        <StepLabel>Welkom</StepLabel>
        <StepContent>
          <Typography gutterBottom>Welkom bij Band-Base! Dit is jouw persoonlijke dashboard. Je vind hier updates van optredens, podia, of artiesten die je volgt.</Typography>
          <Typography variant="subtitle2">Laten we eerst je profiel compleet maken!</Typography>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>Wat kom je doen</StepLabel>
        <StepContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Ik ben een...</FormLabel>
            <RadioGroup value={type} onChange={e => setType(e.target.value as '')}>
              <FormControlLabel label="Fan" value="fan" control={<Radio />} />
              <FormControlLabel label="Artiest" value="artist" control={<Radio />} />
              <FormControlLabel label="Podium" value="podium" control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </StepContent>
      </Step>
      {type === 'fan' ? <Step>
        <StepLabel>Artiesten volgen</StepLabel>
        <StepContent>
          <Typography>Zoek naar artiesten om te volgen</Typography>
        </StepContent>
      </Step> : type === 'artist' ? <Step>
        <StepLabel>Profiel aanmaken</StepLabel>
        <StepContent>
          <FormControl margin="normal" component="fieldset" fullWidth>
            <FormLabel component="legend">Wat is je artiesten naam?</FormLabel>
            <TextField
              id="name"
              name="name"
              label="Artiesten naam"
              //validate={(value: string) => value.length === 0 ? 'Dit veld is verplicht' : false}
              fullWidth
              required
            />
          </FormControl>
          <FormControl margin="normal" component="fieldset" fullWidth>
            <FormLabel component="legend">Wat voor muziek genres passen het beste bij je?</FormLabel>
            <Autocomplete
              id="genre"
              options={[]}
              /*multiple={true}
              getOptionLabel={(option: { title: string, inputValue?: string }) => {
                if (typeof option === 'string') {
                  return option
                }
                if (option.inputValue) {
                  return option.inputValue
                }
                return option.title
              }}
              filterOptions={(options: any, params: any) => {
                const filtered = filter(options, params)
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Add "${params.inputValue}"`,
                  })
                }
                return filtered
              }}
              onChange={(event: any, newValue: any) => {
                setValues({ ...values, genre: newValue })
                setTimeout(() => {
                  onLayoutChange()
                }, 1)
              }}
              renderOption={(option: { title: string }) => option.title}
              validate={(value: Array<{}>) => value.length === 0 ? 'Dit veld is verplicht' : false}
              */
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  //error={touched['genre'] && !!errors['genre']}
                  //helperText={touched['genre'] && errors['genre']}
                  label="Autocomplete"
                />
              )}
            />
          </FormControl>
        </StepContent>
      </Step> : <Step>
        <StepLabel>Profiel aanmaken</StepLabel>
        <StepContent>

        </StepContent>
      </Step>}
    </Stepper>
    <CardActions>
      <Button size="small">Overslaan</Button>
      <span style={{ flex: 1 }} />
      {step === 2 && <Button
        variant="contained"
        color="primary"
        size="small"
        type="submit"
        disabled={/*!dirty || !isValid*/ false}
      >
        Opslaan
      </Button>}
      {step < 2 && <Button
        variant="contained"
        color="primary"
        size="small"
        disabled={step === 1 && type === ''}
        onClick={() => setStep(s => s + 1)}
      >
        Volgende
      </Button>}
    </CardActions>
  </Card>
}

export default OnboardingCard
