import { Box, CircularProgress, SxProps, Theme } from '@mui/material'

const containerStyles: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0,0,0,0.7)'
}

function LoadingPopover() {
  return <Box sx={containerStyles}>
    <CircularProgress size={120} variant="indeterminate" />
  </Box>
}

export default LoadingPopover
