import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline, Theme } from '@mui/material'
import { blue, red } from '@mui/material/colors'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterMoment from '@mui/lab/AdapterMoment'
import type { } from '@mui/lab/themeAugmentation'

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: blue,
    secondary: {
      main: red[700],
    },
    background: {
      default: '#111',
      paper: '#222'
    },
  }
})

const muiTheme = createTheme({
  components: require('../theme/overrides')
}, theme)

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ children, theme }: React.PropsWithChildren<{ theme?: Theme }>) {
  return <ThemeProvider theme={theme || muiTheme}>
    <CssBaseline />
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {children}
    </LocalizationProvider>
  </ThemeProvider>
}
