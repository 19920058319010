import { Button, ButtonGroup, Card, CardContent, CardHeader, Divider } from '@mui/material'
import moment from 'moment'
import { Update } from '../../graphql'

function UpdateCard({ update, editMode = false, onEdit, onDelete }: { update: Update, editMode?: boolean, onEdit?: () => void, onDelete?: () => void }) {
  return <Card sx={{ margin: 1 }}>
    <CardHeader title={moment(update.created).format('D MMM YYYY')} titleTypographyProps={{ variant: 'h6' }} />
    <CardContent>{update.message}</CardContent>
    {editMode && <>
      <Divider />
      <ButtonGroup variant="text" aria-label="text button group" fullWidth>
        <Button color="primary" onClick={onEdit}>Bewerken</Button>
        <Button color="secondary" onClick={onDelete}>Verwijderen</Button>
      </ButtonGroup>
    </>}
  </Card>
}

export default UpdateCard
