import { Components } from '@mui/material'

const MuiBackdrop: Components['MuiBackdrop'] = {
  styleOverrides: {
    root: {
      backgroundColor: 'rgba(0,0,0,0.9)'
    }
  }
}

export default MuiBackdrop
