import { useEffect, useMemo, useRef } from 'react'
import Masonry, { MasonryOptions } from 'react-masonry-component'
import { Box, LinearProgress, useMediaQuery, useTheme } from '@mui/material'
import { EventCard, FeaturedEventCard } from '../events'
import { useAuth } from '../auth/AuthProvider'
import { useParams } from 'react-router-dom'
import { useEventsQuery, useUpdatesQueryLazyQuery } from '../../graphql'

const masonryOptions: MasonryOptions = {
  transitionDuration: 0,
  itemSelector: '.masonry-item',
  stamp: '.masonry-stamp'
}

function Dashboard() {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))
  const { data: { events } = { events: [] } } = useEventsQuery()
  const masonryRef = useRef<any>(null)
  const { user } = useAuth()
  const { id } = useParams<{ id?: string }>()
  const profileId = useMemo(() => id || user?.uid || '', [user, id])
  const [getUpdates, { loading, data: { updates } = { updates: [] } }] = useUpdatesQueryLazyQuery({ fetchPolicy: 'no-cache' })

  useEffect(() => {
    masonryRef.current.masonry.layout()
    masonryRef.current.masonry.stamp('.masonry-stamp')
  }, [updates])

  useEffect(() => {
    getUpdates({ variables: { profileId } })
  }, [profileId, getUpdates])

  return <Box display="flex" flexDirection="column" height="100%">
    <Box display="flex" justifyContent="center" padding={1} flex={1} overflow="auto">
      <Masonry options={masonryOptions} ref={masonryRef} style={{ width: '100%' }}>
        {events.slice(0, 10).map(({ featured, ...event }, k) => <Box width={xl ? '25%' : lg ? '33.3%' : md ? '50%' : sm ? '50%' : '100%'} key={k} className="masonry-item">
          {featured ? <FeaturedEventCard event={event} /> : <EventCard event={event} />}
        </Box>)}
      </Masonry>
    </Box>
    {loading && <LinearProgress variant="indeterminate" color="secondary" />}
  </Box>
}

export default Dashboard
