import { Components } from '@mui/material'

const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    contained: {
      borderRadius: 8,
      borderBottomLeftRadius: 4,
      borderTopRightRadius: 4
    },
    text: {
      textTransform: 'none'
    },
    sizeSmall: {
      textTransform: 'none'
    }
  }
}

export default MuiButton
