import React, { useCallback, useMemo, useState } from 'react'
import { Button, DialogContent, DialogActions, Box, TextField } from '@mui/material'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { Form, Formik } from 'formik'
import createFormikHandlers from '../common/createFormikHandlers'
import { useCreateArtistMutation, useCreateVenueMutation, MeQueryDocument, useFindProfileQuery, Artist, Venue, useUpdateVenueMutation, useUpdateArtistMutation, useGenresQueryLazyQuery } from '../../graphql'
import { CommonDialog, LoadingPopover } from '../common'
import MultiAutocomplete from '../common/Autocomplete'
import { AvatarEditor } from '.'
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage'
import { useAuth } from '../auth/AuthProvider'
import { v4 as uuid } from 'uuid'

type InitialArtistValues = Omit<Artist, 'avatar'> & { avatar?: string | null, avatarUrl?: string | null }

const initialArtistValues = {
  name: '',
  avatar: '',
  genres: [] as Array<{ title: string }>,
  members: []
} as unknown as InitialArtistValues

function validateArtistProfile(values: InitialArtistValues) {
  let errors = {}
  if (!values.name || values.name === '') {
    errors = { ...errors, name: 'Dit veld is verplicht' }
  }
  if (values.genres.length === 0) {
    errors = { ...errors, genres: 'Kies tenminste één genre' }
  }
  return errors
}

let timeout: NodeJS.Timeout

function ArtistForm({ artist }: { artist?: Artist }) {
  const [typing, setTyping] = useState(false)
  const history = useHistory()
  const { user } = useAuth()
  const [createArtist] = useCreateArtistMutation({ refetchQueries: [MeQueryDocument], awaitRefetchQueries: true })
  const [updateArtist] = useUpdateArtistMutation({ refetchQueries: [MeQueryDocument], awaitRefetchQueries: true })
  const [searchGenres, { data: { genres } = { genres: [] }, loading }] = useGenresQueryLazyQuery()

  const handleSearchGenres = useCallback((q: string) => {
    setTyping(true)
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setTyping(false)
      searchGenres({ variables: { q } })
    }, 300)
  }, [searchGenres])

  const handleSubmit = useCallback(async ({ avatarUrl, followers, ...values }: InitialArtistValues) => {
    let avatar = values.avatar
    if (avatarUrl) {
      const storage = getStorage()
      const avatarRef = ref(storage, `images/${user?.uid}/${values.id || uuid()}.png`)
      const result = await uploadString(avatarRef, avatarUrl, 'data_url')
      avatar = await getDownloadURL(result.ref)
    }
    if (values.id) {
      await updateArtist({ variables: { data: { ...values, avatar, genres: values.genres.map(({ title }) => ({ title })) } } })
      history.push(`/profile/${values.id}`)
    } else {
      const result = await createArtist({ variables: { data: { ...values, avatar, genres: values.genres.map(({ title }) => ({ title })) } } })
      history.push(`/profile/${result.data?.createArtist.id}`)
    }
  }, [history, createArtist, updateArtist, user])

  return <Formik initialValues={artist || initialArtistValues} validate={validateArtistProfile} onSubmit={handleSubmit}>
    {({ isValid, dirty, isSubmitting, values, ...formik }) => <Form noValidate style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      {isSubmitting && <LoadingPopover />}
      <AvatarEditor onChange={avatarUrl => formik.setFieldValue('avatarUrl', avatarUrl)} value={values.avatar} />
      <DialogContent>
        <TextField
          {...createFormikHandlers({ fieldName: 'name', values, ...formik })}
          label="Artiesten naam"
          fullWidth
          required
        />
        <MultiAutocomplete
          options={genres.map(({ count, ...v }) => v)}
          value={values.genres}
          onInputChange={(e, v) => handleSearchGenres(v)}
          loading={loading || typing}
          loadingText="Zoeken..."
          onChange={(event, newValue) => formik.setFieldValue('genres', newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={`Muziek genre${values.genres.length > 1 ? 's' : ''}`}
              placeholder="Kies een (of meerdere) muziek genre(s)"
              required
            />
          )}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <span />
        <Button color="primary" type="submit" variant="contained" disabled={isSubmitting || !dirty || !isValid}>{artist?.id ? 'Opslaan' : 'Toevoegen'}</Button>
      </DialogActions>
    </Form>}
  </Formik>
}

type InitialVenueValues = Omit<Venue, 'avatar'> & { avatar?: string | null, avatarUrl?: string | null, address?: string }

const initialVenueValues = {
  name: '',
  avatar: '',
  address: ''
} as unknown as InitialVenueValues

function validateVenueProfile(values: InitialVenueValues) {
  let errors = {}
  if (!values.name || values.name === '') {
    errors = { ...errors, name: 'Dit veld is verplicht' }
  }
  if (!values.address || values.address === '') {
    errors = { ...errors, address: 'Dit veld is verplicht' }
  }
  return errors
}

function VenueForm({ venue }: { venue?: Venue }) {
  const history = useHistory()
  const [createVenue] = useCreateVenueMutation({ refetchQueries: [MeQueryDocument], awaitRefetchQueries: true })
  const [updateVenue] = useUpdateVenueMutation({ refetchQueries: [MeQueryDocument], awaitRefetchQueries: true })
  const { user } = useAuth()

  const handleSubmit = useCallback(async ({ address = '', avatarUrl, ...values }: InitialVenueValues) => {
    let avatar = values.avatar
    if (avatarUrl) {
      const storage = getStorage()
      const avatarRef = ref(storage, `images/${user?.uid}/${values.id || uuid()}.png`)
      const result = await uploadString(avatarRef, avatarUrl, 'data_url')
      avatar = await getDownloadURL(result.ref)
    }
    if (values.id) {
      await updateVenue({ variables: { data: { ...values, avatar, location: { address } } } })
      history.push(`/profile/${values.id}`)
    } else {
      const result = await createVenue({ variables: { data: { ...values, avatar, location: { address } } } })
      history.push(`/profile/${result.data?.createVenue.id}`)
    }
  }, [history, createVenue, updateVenue, user])

  return <Formik initialValues={(venue ? { ...venue, address: venue.location.address } : undefined) || initialVenueValues} validate={validateVenueProfile} onSubmit={handleSubmit}>
    {({ isValid, dirty, isSubmitting, values, ...formik }) => <Form style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
      {isSubmitting && <LoadingPopover />}
      <AvatarEditor onChange={avatarUrl => formik.setFieldValue('avatarUrl', avatarUrl)} value={values.avatar} />
      <DialogContent>
        <TextField
          {...createFormikHandlers({ fieldName: 'name', values, ...formik })}
          label="Podium naam"
          fullWidth
          required
        />
        <TextField
          {...createFormikHandlers({ fieldName: 'address', values, ...formik })}
          label="Adres"
          fullWidth
          required
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <span />
        <Button color="primary" type="submit" variant="contained" disabled={isSubmitting || !dirty || !isValid}>{venue?.id ? 'Opslaan' : 'Toevoegen'}</Button>
      </DialogActions>
    </Form>}
  </Formik>
}

function AddProfile() {
  const location = useLocation<{ referer?: string }>()
  const routeMatch = useRouteMatch()
  const params = useParams<{ profileId?: string, type?: 'artist' | 'venue' }>()
  const history = useHistory()
  const { data: { findProfile: profile } = { findProfile: undefined } } = useFindProfileQuery({ variables: { id: params.profileId } })
  const open = useMemo(() => routeMatch.path === '/profile/add/:type?' || routeMatch.path === '/profile/:profileId/edit', [routeMatch])
  const type = useMemo(() => params.type || profile?.type.toLowerCase(), [params.type, profile?.type])

  return <CommonDialog open={open} title={profile?.id ? 'Profiel bewerken' : type ? `${type === 'artist' ? 'Artiest of band' : 'Podium'} toevoegen` : 'Artiest, band of podium toevoegen'}>
    {!type ? <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Button variant="contained" color="primary" size="large" onClick={() => history.push('/profile/add/artist', location.state)}>Artiest of band toevoegen</Button>
        <span>&nbsp;</span>
        <Button variant="contained" color="primary" size="large" onClick={() => history.push('/profile/add/venue', location.state)}>Podium toevoegen</Button>
      </Box>
    </DialogContent> : type === 'artist' ? <ArtistForm artist={profile as Artist} /> : <VenueForm venue={profile as Venue} />}
  </CommonDialog>
}

export default AddProfile
