import { Box, Card, CardActionArea, CardContent, Divider, Icon, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { Event } from '../../graphql'
import EventDetailsCardMedia from './EventDetailsCardMedia'

function FeaturedEventCard({ event }: { event: Event }) {
  const history = useHistory()
  const { pathname } = useLocation()
  return <Card style={{ margin: 8 }}>
    <CardActionArea onClick={() => history.push(`/events/${event.id}`, { referer: pathname })}>
      <EventDetailsCardMedia event={event} gig={event.gigs.length === 1 ? event.gigs[0] : undefined} />
      <Box display="flex" alignItems="center">
        <List disablePadding dense style={{ flex: 1 }}>
          <ListItem>
            <ListItemIcon style={{ minWidth: 36 }}><Icon>place</Icon></ListItemIcon>
            <ListItemText primary={<span style={{ whiteSpace: 'nowrap' }}>{event.venue.name}</span>} secondary="[event.location.address]" />
          </ListItem>
        </List>
        <List disablePadding dense style={{ flex: 1 }}>
          <ListItem>
            <ListItemIcon style={{ minWidth: 36 }}><Icon>today</Icon></ListItemIcon>
            <ListItemText primary={<span style={{ whiteSpace: 'nowrap' }}>{moment(event.from).format('D MMMM YYYY')}</span>} secondary={moment(event.from).format('HH:mm')} />
          </ListItem>
        </List>
      </Box>
      {event.gigs.length === 1 && <>
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary">{event.gigs[0].description}</Typography>
        </CardContent>
      </>}
    </CardActionArea>
  </Card>
}

export default FeaturedEventCard
