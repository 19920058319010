import { Button, TextField, DialogContent, DialogActions } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Gig, ProfileType, Venue } from '../../graphql'
import { MobileDateTimePicker } from '@mui/lab'
import moment from 'moment'
import createFormikHandlers from '../common/createFormikHandlers'
import SearchArtist from '../common/SearchArtist'
import { ImageUploadContainer } from '../common'

type InitialGig = Omit<Gig, 'cover'> & { cover: File | string }

function validateGig(values: Omit<Gig, 'cover'> & { cover: File | string }) {
  const errors: any = {}
  if (!values.artist) {
    errors.artist = 'Kies een artiest'
  }
  if (!values.from) {
    errors.from = 'Kies een starttijd'
  }
  if (!values.to) {
    errors.to = 'Kies een eindtijd'
  }
  return errors
}

function AddGig({ gig, isEditing, onGoBack, onSubmit }: { gig: Gig, isEditing: boolean, onGoBack: (values: { from?: Date, venue?: Venue }) => void, onSubmit: (values: InitialGig) => void }) {
  const location = useLocation<{ referer?: string, from?: Date, venue?: Venue, profile?: { name: string, id: string, type: ProfileType } }>()

  return <Formik initialValues={gig as InitialGig} validate={validateGig} onSubmit={onSubmit}>
    {({ isValid, dirty, isSubmitting, values, ...formik }) => <Form style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
      <>
        <ImageUploadContainer uploadText="Upload een cover afbeelding" onChange={file => formik.setFieldValue('cover', file)} value={values.cover instanceof File ? URL.createObjectURL(values.cover) : values.cover as string}>
          <SearchArtist
            value={values.artist}
            onChange={(event, newValue) => {
              if (typeof newValue !== 'string') {
                formik.setFieldValue('artist', newValue as any)
              }
            }}
            disabled={location.state?.profile?.type === ProfileType.Artist && location.state?.profile?.id === values.artist?.id}
          />
        </ImageUploadContainer>
        <DialogContent sx={{ paddingTop: 0 }}>
          <MobileDateTimePicker
            label="Starttijd"
            inputFormat="HH:mm"
            cancelText="Annuleren"
            okText="OK"
            todayText="Vandaag"
            disableCloseOnSelect={false}
            onChange={value => formik.setFieldValue('from', value ? new Date(value) : null)}
            value={values.from}
            renderInput={(params) => <TextField {...params} fullWidth required />}
            minutesStep={5}
            openTo="hours"
          />
          <MobileDateTimePicker
            label="Eindtijd"
            inputFormat="HH:mm"
            cancelText="Annuleren"
            okText="OK"
            todayText="Vandaag"
            disableCloseOnSelect={false}
            minDateTime={moment(values.from)}
            onChange={value => formik.setFieldValue('to', value)}
            value={values.to}
            renderInput={(params) => <TextField {...params} fullWidth required />}
            minutesStep={5}
            openTo="hours"
          />
          <TextField
            {...createFormikHandlers({ fieldName: 'description', values, ...formik })}
            label="Opmerkingen"
            fullWidth
            multiline
            rows={5}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onGoBack({ from: location.state?.from, venue: location.state?.venue })}>Vorige</Button>
          <span style={{ flex: 1 }} />
          <Button type="submit" variant="contained" size="small" disabled={(!dirty && !isEditing) || !isValid}>{isEditing ? 'Opslaan' : 'Toevoegen'}</Button>
        </DialogActions>
      </>
    </Form>
    }
  </Formik >
}

export default AddGig
