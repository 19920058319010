import { Avatar, Card, CardContent, Divider, TextField, List, ListItem, ListItemAvatar, ListItemText, Button, CardActions } from '@mui/material'
import { useCallback, useState } from 'react'
import { Artist, UpdatesQueryDocument, useCreateUpdateMutation, Venue } from '../../graphql'
import { useAuth } from '../auth/AuthProvider'

function EventsCard({ profile }: { profile?: Artist | Venue }) {
  const { user } = useAuth()
  const [message, setMessage] = useState('')
  const [createUpdate] = useCreateUpdateMutation()

  const handleShare = useCallback(async () => {
    await createUpdate({ variables: { data: { created: new Date(), message }, profileId: profile?.id || '' }, refetchQueries: [{ query: UpdatesQueryDocument, variables: { profileId: profile?.id } }], awaitRefetchQueries: true })
    setMessage('')
  }, [message, profile, createUpdate])

  return <Card sx={{ margin: 1 }}>
    <List disablePadding>
      <ListItem>
        <ListItemAvatar>
          <Avatar src={profile?.avatar || ''} alt={profile?.name.substring(0, 2).toUpperCase()} />
        </ListItemAvatar>
        <ListItemText
          primary="Delen als"
          primaryTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
          secondary={profile?.name || user?.displayName}
          secondaryTypographyProps={{ variant: 'body1', color: 'textPrimary' }}
        />
      </ListItem>
    </List>
    <Divider />
    <CardContent>
      <TextField multiline rows={5} fullWidth placeholder="Scrijf iets om te delen..." margin="none" value={message} onChange={e => setMessage(e.target.value)} />
    </CardContent>
    <CardActions>
      <Button variant="contained" disabled={!message || message === ''} onClick={handleShare}>Delen</Button>
    </CardActions>
  </Card>
}

export default EventsCard
