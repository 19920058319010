import { Box, Icon, IconButton, SxProps, Theme, Typography } from '@mui/material'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'

type ImageUploadContainerProps = {
  sx?: SxProps<Theme>,
  children?: ReactNode | ((files: File[]) => ReactNode),
  uploadText: string,
  onChange?: (file: File | undefined) => void,
  value?: string | null
  hideControls?: boolean,
  disablePreview?: boolean
}

function ImageUploadContainer({ uploadText, children, onChange, value, hideControls, disablePreview, sx }: ImageUploadContainerProps) {
  const [files, setFiles] = useState([] as Array<File & { preview: string }>)

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })))
    onChange && onChange(acceptedFiles[0])
  }, [onChange])

  const handleRemove = useCallback((e: any) => {
    e.stopPropagation()
    setFiles([])
    onChange && onChange(undefined)
  }, [onChange])

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  return <Dropzone onDrop={handleDrop} maxFiles={1} multiple={false} accept="image/*">
    {({ getRootProps, getInputProps }) => (
      <Box sx={{ backgroundSize: 'cover', backgroundImage: !disablePreview && (value || files.length > 0) ? `url(${value || files[0].preview})` : undefined }}>
        <Box sx={{ minHeight: 180, display: 'flex', paddingX: 3, flexDirection: 'column', background: 'rgba(0,0,0,0.4)', ...sx }}>
          <Box {...getRootProps()} sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <input {...getInputProps()} />
            {hideControls ? null : files.length > 0 || value ? <>
              <IconButton><Icon fontSize="large">change_circle</Icon></IconButton>
              <IconButton onClick={handleRemove}><Icon fontSize="large">cancel</Icon></IconButton>
            </> : <Typography>{uploadText}</Typography>}
          </Box>
          {typeof children === 'function' ? children(files) : children}
        </Box>
      </Box>
    )}
  </Dropzone>
}

export default ImageUploadContainer
