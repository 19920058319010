import { ButtonProps, Icon } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useToggleFollowArtistMutation, Artist, ProfileType, FindProfileQueryDocument } from '../../graphql'
import { useAuth } from '../auth/AuthProvider'

// TODO: ask user to provide home address
function ArtistFollowButton({ artist, ...buttonProps }: ButtonProps & { artist: Artist }) {
  const [toggleFollowArtist, { loading }] = useToggleFollowArtistMutation({ refetchQueries: [{ query: FindProfileQueryDocument, variables: { id: artist.id, type: ProfileType.Artist } }], awaitRefetchQueries: true })
  const { user } = useAuth()
  return <LoadingButton
    size="large"
    variant="contained"
    {...buttonProps}
    loading={loading}
    onClick={() => toggleFollowArtist({ variables: { artistId: artist.id } })}
    loadingPosition="start"
    startIcon={<Icon>{(artist.followers || []).indexOf(user?.uid || '') >= 0 ? 'favorite' : 'favorite_border'}</Icon>}>
    {(artist.followers || []).indexOf(user?.uid || '') >= 0 ? 'Volgend' : 'Volgen'}
  </LoadingButton>
}

export default ArtistFollowButton
