import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useEventQueryLazyQuery } from '../../graphql'
import { InitialState } from './AddEvent'
import { LoadingPopover } from '../common'

// TODO: test this...
function QueryEvent({ initialValues, onLoad }: { initialValues: InitialState, onLoad: (event: InitialState) => void }) {
  const { eventId } = useParams<{ eventId?: string }>()
  const [getEvent, { data: { event } = { event: undefined as Event | undefined } }] = useEventQueryLazyQuery({ fetchPolicy: 'no-cache' })
  useEffect(() => {
    if (eventId) {
      getEvent({ variables: { eventId } })
    } else {
      onLoad(initialValues)
    }
  }, [eventId, getEvent, onLoad, initialValues])
  useEffect(() => event ? onLoad(event as unknown as InitialState) : onLoad(initialValues), [event, onLoad, initialValues])
  return <LoadingPopover />
}

export default QueryEvent
