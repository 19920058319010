import { getDownloadURL, getMetadata, getStorage, listAll, ref } from 'firebase/storage'
import { atom } from 'jotai'

const policiesRefAtom = atom(() => {
  const storage = getStorage()
  return ref(storage, 'policies')
})

const policiesAtom = atom(async (get) => {
  const policiesRef = get(policiesRefAtom)
  const files = await Promise.all((await listAll(policiesRef)).items.map(async fileRef => {
    const metadata = await getMetadata(fileRef)
    const downloadURL = await getDownloadURL(fileRef)
    const content = atom(async () => {
      return await (await fetch(downloadURL)).text()
    })
    return { ...metadata, downloadURL, content }
  }))
  return files
})

export default policiesAtom
