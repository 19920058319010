import { CircularProgress } from '@mui/material'
import CenteredPage from './CenteredPage'

function Loading() {
  return <CenteredPage>
    <CircularProgress size={120} variant="indeterminate" color="secondary" />
  </CenteredPage>
}

export default Loading
