import { AppBar as MuiAppBar, AppBarProps, Button, Icon, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'

function AppBar({ onShowSidebar, isInSidebar, onHideSidebar, hideLogin = false, ...appBarProps }: { onShowSidebar?: () => void, onHideSidebar?: () => void, isInSidebar?: boolean, hideLogin?: boolean } & AppBarProps) {
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return <MuiAppBar position={isInSidebar ? 'relative' : undefined} {...appBarProps}>
    <Toolbar style={{ justifyContent: isMobile ? 'space-between' : 'start' }}>
      {onShowSidebar && !isInSidebar && <IconButton onClick={onShowSidebar} style={{ marginLeft: -16 }}><Icon>menu</Icon></IconButton>}
      <Typography variant="h5" style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>Band-Base</Typography>
      <span style={{ flex: 1 }} />
      {!isInSidebar && !hideLogin && <Button size="small" onClick={() => history.push('/login')} color="inherit">Login</Button>/* TODO: && not logged in */}
      {isInSidebar && isMobile && <IconButton onClick={onHideSidebar} style={{ marginRight: -16 }}><Icon>close</Icon></IconButton>}
    </Toolbar>
  </MuiAppBar>
}

export default AppBar
