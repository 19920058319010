import { Components } from '@mui/material'

const MuiPaper: Components['MuiPaper'] = {
  styleOverrides: {
    root: {
      background: '#222'
    }
  }
}

export default MuiPaper
