import { useCallback } from 'react'
import { FindEventsByArtistQueryDocument, FindEventsByVenueQueryDocument, Update, Event, UpdatesQueryDocument, useDeleteEventMutation, useDeleteUpdateMutation, EventsQueryDocument } from '../../graphql'
import { useConfirmationDialog } from '../common/ConfirmationDialog'
import { EventCard } from '../events'
import { useHistory, useLocation } from 'react-router'
import { UpdateCard } from '../updates'

type ManageContentCardProps = {
  item: (Update | Event) & { type: 'update' | 'event' },
  profileId: string
}

function ManageContentCard({ item: { type, ...item }, profileId }: ManageContentCardProps) {
  const [deleteUpdate] = useDeleteUpdateMutation({ refetchQueries: [{ query: UpdatesQueryDocument, variables: { profileId } }], awaitRefetchQueries: true })
  const [deleteEvent] = useDeleteEventMutation()
  const confirmDialog = useConfirmationDialog()
  const history = useHistory()
  const location = useLocation()

  const handleEditEvent = useCallback(() => {
    history.push(`/events/edit/${item.id}`, { referer: location.pathname })
  }, [history, item.id, location.pathname])

  const handleDeleteUpdate = useCallback(() => {
    confirmDialog.open({
      dialogContent: 'Weet je zeker dat je dit bericht wilt verwijderen?',
      onConfirm: async () => {
        await deleteUpdate({ variables: { updateId: item.id } })
      }
    })
  }, [confirmDialog, deleteUpdate, item.id])

  const handleDeleteEvent = useCallback(() => {
    confirmDialog.open({
      dialogContent: 'Weet je zeker dat je dit bericht wilt verwijderen?',
      onConfirm: async () => {
        const refetchQueries = [
          { query: EventsQueryDocument },
          { query: FindEventsByVenueQueryDocument, variables: { venueId: profileId } },
          { query: FindEventsByArtistQueryDocument, variables: { artistId: profileId } }
        ]
        await deleteEvent({ variables: { eventId: item.id }, refetchQueries, awaitRefetchQueries: true })
      }
    })
  }, [confirmDialog, deleteEvent, item.id, profileId])

  switch (type) {
    case 'update': return <UpdateCard update={item as Update} editMode={true} onDelete={handleDeleteUpdate} />
    case 'event': return <EventCard event={item as Event} editMode={true} onDelete={handleDeleteEvent} onEdit={handleEditEvent} />
    default: return null
  }
}

export default ManageContentCard
