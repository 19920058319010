import { CenteredPage, RenderMarkdown } from '../common'
import { useState, useMemo, useEffect } from 'react'
import { Timestamp } from 'firebase/firestore'
import { Button } from '@mui/material'
import { FullMetadata } from 'firebase/storage'

type Policy = FullMetadata & { content: string }

function Policies({ policies, onAccept }: { policies: Policy[], onAccept: (accepted: { [key: string]: Timestamp }) => void }) {
  const [accepted, setAccepted] = useState<{ [key: string]: Timestamp }>({})
  const policy: Policy | null = useMemo(() => {
    return policies.filter(p => !accepted[p.name])[0]
  }, [accepted, policies])

  useEffect(() => {
    if (!policy && Object.keys(accepted).length > 0) {
      onAccept(accepted)
    }
  }, [accepted, policy, onAccept])

  return <CenteredPage>
    {policy ? <>
      <RenderMarkdown>{policy.content}</RenderMarkdown>
      <Button onClick={() => setAccepted(a => ({ ...a, [policy.name]: Timestamp.fromDate(new Date(policy.updated)) }))} variant="contained" size="large" color="primary">Accepteren</Button>
    </> : null}
  </CenteredPage>
}

export default Policies
