import { Components } from '@mui/material'

const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    variant: 'filled',
    color: 'primary',
    margin: 'normal'
  }
}

export default MuiTextField
