import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import SocialSignin, { SocialProviders } from './SocialSignin'
import { getAuth, signInWithPopup, sendSignInLinkToEmail } from 'firebase/auth'
import { providers } from './AuthProvider'
import { Box, Button, Card, CardActions, CardContent, Divider, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import createFormikHandlers from '../common/createFormikHandlers'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const actionCodeSettings = {
  url: window.location.href.replace(window.location.pathname, '/'),
  handleCodeInApp: true
}

function validateEmail(email: string) {
  return emailRegex.test(String(email).toLowerCase()) ? null : 'This is not a valid e-mailaddress'
}

type SignupFormProps = {
  socialButtonText: string,
  buttonText: string,
  content?: React.ReactNode
}

function SignupForm({ buttonText, socialButtonText, content }: SignupFormProps) {
  const history = useHistory()
  const auth = getAuth()

  const handleSubmit = useCallback(async ({ email }: { email: string }) => {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    localStorage.setItem('emailForSignIn', email)
    history.push('/signup/verify')
  }, [auth, history])

  const handleValidateForm = useCallback(({ email }: { email: string }) => {
    const invalidEmail = validateEmail(email)
    return !invalidEmail ? {} : { email: invalidEmail }
  }, [])

  const handleClick = useCallback(async (provider: SocialProviders) => {
    await signInWithPopup(auth, providers[provider])
    history.push('/')
  }, [auth, history])

  return <Formik initialValues={{ email: '' }} onSubmit={handleSubmit} validate={handleValidateForm}>
    {({ isValid, dirty, isSubmitting, ...formik }) => {
      return <Card component={Form} style={{ marginBottom: 24 }}>
        <CardContent>
          {content}
          <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
            <TextField
              {...createFormikHandlers({ fieldName: 'email', ...formik })}
              label="Jouw e-mailadres"
              placeholder="example@mail.com"
              fullWidth
              required
            />
          </Box>
        </CardContent>
        <CardActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || !dirty || !isValid}>{buttonText}</Button>
        </CardActions>
        <Divider />
        <CardContent>
          <SocialSignin
            text={socialButtonText}
            onClick={provider => handleClick(provider)}
            marginTop={1}
            flexDirection="column"
            alignItems="center"
            ButtonProps={{ variant: 'contained', color: 'secondary' }}
          />
        </CardContent>
      </Card>
    }}
  </Formik>
}

export default SignupForm
