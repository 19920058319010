import { Icon, Input, InputAdornment, styled } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

const StyledInput = styled(Input)(({ theme }) => ({
  borderRadius: 24,
  background: theme.palette.background.default,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginTop: theme.spacing(1)
}))

let timeout: NodeJS.Timeout

function SearchField() {
  const history = useHistory()
  const { search } = useParams<{ search?: string }>()
  const [value, setValue] = useState<string>(search || '')

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (value.length >= 3) {
        history.push(`/browse/${e.target.value}`)
      }
    }, 300)
  }, [value, history])

  const handleSubmit = useCallback((e: React.FormEvent) => {
    e.preventDefault()
    history.push(`/browse/${value}`)
  }, [value, history])

  return <form onSubmit={handleSubmit} style={{ width: '100%' }}>
    <StyledInput
      fullWidth
      placeholder="Zoek artiesten of events..."
      startAdornment={<InputAdornment position="start"><Icon color="action">search</Icon></InputAdornment>}
      disableUnderline
      value={value}
      onChange={handleChange}
    />
  </form>
}

export default SearchField
