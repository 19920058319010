import React, { useCallback } from 'react'
import { Dialog, AppBar, Icon, IconButton, Toolbar, Typography, useTheme, useMediaQuery, Slide, DialogProps } from '@mui/material'
import { useHistory, useLocation } from 'react-router'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />
})

function CommonDialog({ children, open, onClose, title, ...props }: React.PropsWithChildren<{ title: string, onClose?: () => void } & Omit<DialogProps, 'onClose'>>) {
  const location = useLocation<{ referer?: string }>()
  const history = useHistory()
  const theme = useTheme()
  const isFullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = useCallback(() => {
    onClose && onClose()
    history.push(location.state && location.state.referer ? location.state.referer : '/', {})
  }, [history, location, onClose])

  return <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    fullScreen={isFullscreen}
    fullWidth
    maxWidth="xs"
    {...props}
  >
    <AppBar position="absolute">
      <Toolbar style={{ justifyContent: !isFullscreen ? 'space-between' : 'start' }}>
        {isFullscreen && <IconButton onClick={handleClose} style={{ marginLeft: -16 }}><Icon>chevron_left</Icon></IconButton>}
        {isFullscreen ? <Typography variant="h5" style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>Band-Base</Typography> : <Typography variant="h5">{title}</Typography>}
        <span />
        {!isFullscreen && <IconButton onClick={handleClose} style={{ marginRight: -16 }}><Icon>close</Icon></IconButton>}
      </Toolbar>
    </AppBar>
    <Toolbar />
    {children}
  </Dialog>
}

export default CommonDialog
