import React from 'react'
import { Avatar, AvatarGroup, Box, CardMedia, Typography, styled } from '@mui/material'
import moment from 'moment'
import { Event, Gig } from '../../graphql'

const CardTitle = styled(Typography)({
  textShadow: '0 0 10px rgba(0,0,0,0.9)',
  marginBottom: 24
})

const CardSubTitle = styled(Typography)({
  textShadow: '0 0 10px rgba(0,0,0,0.9)',
  marginBottom: 16
})

const StyledAvatar = styled(Avatar)({
  width: 96,
  height: 96,
  marginBottom: 24
})

const StyledAvatarGroup = styled(AvatarGroup)({
  marginBottom: 24
})

const StyledCardMedia = styled(CardMedia)({
  flex: 1
})

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingTop: theme.spacing(8),
  background: 'rgba(0,0,0,0.4)'
}))

function EventDetailsCardMedia({ gig, event, showBack, children }: React.PropsWithChildren<{ gig?: Gig, event?: Event, showBack?: boolean }>) {
  return <StyledCardMedia image={gig?.cover || event?.cover || undefined}>
    <StyledContainer display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
      {gig ? <StyledAvatar><img src={gig.artist.avatar || ''} alt="" /></StyledAvatar> : <StyledAvatarGroup max={4}>
        {event?.gigs.map((gig, n) => <Avatar key={n}>
          <img src={gig.artist.avatar || ''} alt="" />
        </Avatar>)}
      </StyledAvatarGroup>}
      <CardSubTitle variant="h6" align="center">{moment(event?.from).format('D MMMM YYYY')}</CardSubTitle>
      <CardTitle variant="h4" align="center">{gig && showBack ? `${gig?.artist.name} live op ${event?.venue.name}!` : event?.title}</CardTitle>
      {children}
    </StyledContainer>
  </StyledCardMedia>
}

export default EventDetailsCardMedia
