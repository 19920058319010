import { Components } from '@mui/material'
import { theme } from '../../config/theme'

const MuiListItem: Components['MuiListItem'] = {
  styleOverrides: {
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        background: 'rgba(255, 255, 255, 0.08)',
        color: theme.palette.common.white
      }
    }
  }
}

export default MuiListItem
