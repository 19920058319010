import { Typography } from '@mui/material'
import ReactMarkdown, { Components } from 'react-markdown'

const h1: Components['h1'] = ({ node, color, ref, ...props }) => <Typography variant="h1" gutterBottom {...props} />
const p: Components['p'] = ({ node, color, ref, ...props }) => <Typography variant="body2" gutterBottom {...props} />

function RenderMarkdown({ children }: { children: string }) {
  return <ReactMarkdown components={{ h1, p }}>{children}</ReactMarkdown>
}

export default RenderMarkdown
