import React from 'react'
import { FormikTouched, FormikErrors } from 'formik'

type CreateFormikHandlersParams = {
  fieldName: string,
  values: { [key: string]: any },
  touched: FormikTouched<{ [key: string]: any }>,
  errors: FormikErrors<{ [key: string]: any }>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void,
}

function createFormikHandlers({ fieldName, values, touched, errors, setFieldValue, setFieldTouched }: CreateFormikHandlersParams) {
  return {
    name: fieldName,
    value: values[fieldName],
    onChange: (e => {
      setFieldTouched(fieldName)
      setFieldValue(fieldName, e.target.value)
    }) as React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
    onBlur: (() => setFieldTouched(fieldName)) as React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>,
    error: touched[fieldName] && Boolean(errors[fieldName]),
    helperText: touched[fieldName] && errors[fieldName]
  }
}

export default createFormikHandlers
