import { Components } from '@mui/material'

const MuiTypography: Components['MuiTypography'] = {
  styleOverrides: {
    gutterBottom: {
      marginBottom: 24
    },
    subtitle1: {
      fontSize: 18
    }
  }
}

export default MuiTypography
