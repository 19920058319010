import { Avatar, Box, Divider, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Artist, Update, Event, useFindEventsByArtistQuery, useUpdatesQuery } from '../../graphql'
import Masonry, { MasonryOptions } from 'react-masonry-component'
import { useCallback, useMemo, useRef } from 'react'
import { EventCard } from '../events'
import { UpdateCard } from '../updates'
import { ArtistFollowButton } from '.'

const StyledAvatar = styled(Avatar)({
  width: 96,
  height: 96,
  marginRight: 24
})

const masonryOptions: MasonryOptions = {
  transitionDuration: 0,
  itemSelector: '.masonry-item',
  stamp: '.masonry-stamp'
}

function ArtistProfile({ artist }: { artist: Artist }) {
  const { data: { findEventsByArtist: events } = { findEventsByArtist: [] } } = useFindEventsByArtistQuery({ variables: { artistId: artist.id } })
  const { data: { updates } = { updates: [] } } = useUpdatesQuery({ variables: { profileId: artist.id } })
  const masonryRef = useRef<any>(null)
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))
  const sortEventsAndUpdates = useCallback((a: { created: Date }, b: { created: Date }) => a.created > b.created ? 1 : b.created > a.created ? -1 : 0, [])
  const eventsAndUpdates = useMemo(() => [
    ...updates.map(u => ({ ...u, type: 'update' as 'update' })),
    ...events.map(e => ({
      ...e,
      type: 'event' as 'event',
      created: e.from
    }))].sort(sortEventsAndUpdates), [events, updates, sortEventsAndUpdates])
  const renderContent = useCallback((type: 'update' | 'event', item: Update | Event) => {
    switch (type) {
      case 'update': return <UpdateCard update={item as Update} />
      case 'event': return <EventCard event={item as Event} />
      default: return null
    }
  }, [])
  return <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
    <Box sx={{ minHeight: 293, height: 293, padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(\'./img/concert_001.jpg\')', backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <StyledAvatar src={artist.avatar || ''} alt={artist.name} />
        <Typography variant="h2">{artist.name}</Typography>
      </Box>
      <Typography variant="body2" gutterBottom>{`${(artist.followers || []).length} volger${(artist.followers || []).length !== 1 ? 's' : ''}`}</Typography>
      <ArtistFollowButton artist={artist} />
    </Box>
    <Divider />
    <Box sx={{ flex: 1, padding: 1 }}>
      <Masonry options={masonryOptions} ref={masonryRef} style={{ width: '100%' }}>
        {eventsAndUpdates.map(({ type, ...item }, k) => <Box width={xl ? '25%' : lg ? '33.3%' : md ? '50%' : sm ? '50%' : '100%'} key={k} className="masonry-item">
          {renderContent(type, item)}
        </Box>)}
      </Masonry>
    </Box>
  </Box>
}

export default ArtistProfile
