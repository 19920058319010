import React, { useState } from 'react'
import { Sidebar } from './modules/common'
import { Route } from 'react-router-dom'
import { AppBar } from './modules/common'
import { Toolbar, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/material'

function App({ children }: React.PropsWithChildren<{}>) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [showSidebar, setShowSidebar] = useState(false)

  return <>
    {isMobile && <Toolbar />}
    <Box display="flex" height="100%">
      {isMobile && <AppBar onShowSidebar={() => setShowSidebar(true)} color="primary" />}
      <Route path={['/browse/:search', '/']}>
        <Sidebar open={showSidebar} onClose={() => setShowSidebar(false)} />
      </Route>
      <Box flex={1} width="100%">
        {children}
      </Box>
    </Box>
  </>
}

export default App
