import { Signup, Login } from './'
import { AppBar } from '../common'
import { Box } from '@mui/material'
import { Route, Switch } from 'react-router'
import EmailSent from './EmailSent'

function Auth() {
  return <Box height="100%" width="100%" overflow="auto" style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(\'/img/concert_001.jpg\')', backgroundPosition: 'center', backgroundSize: 'cover' }}>
    <AppBar color="default" />
    <Switch>
      <Route path="/signup/verify" component={EmailSent} />
      <Route path="/signup" component={Signup} />
      <Route path="/login" component={Login} />
    </Switch>
  </Box>
}

export default Auth
