import { Components } from '@mui/material'
import { theme } from '../../config/theme'

const MuiListSubheader: Components['MuiListSubheader'] = {
  styleOverrides: {
    sticky: {
      backgroundColor: theme.palette.background.paper,
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}

export default MuiListSubheader
