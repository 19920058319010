import { FormEvent, useCallback, useMemo, useState } from 'react'
//import { useHistory } from 'react-router'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { CenteredPage } from '../common'
import { Box, Button, Card, CardActions, CardContent, CardHeader, TextField } from '@mui/material'

function PasswordReset() {
  //const history = useHistory()
  const [email, setEmail] = useState('')
  const isValid = useMemo(() => email.length > 0, [email])
  const auth = getAuth()

  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.preventDefault()
    await sendPasswordResetEmail(auth, email)
    console.log('submit')
    // TODO: direct to reset password email sent page
  }, [auth, email])

  return <CenteredPage>
    <Card component="form" onSubmit={handleSubmit}>
      <CardHeader title="Wachtwoord resetten" />
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start">
          <TextField label="Your e-mail" name="email" type="email" value={email} onChange={e => setEmail(e.target.value)} variant="filled" color="primary" margin="normal" fullWidth />
        </Box>
      </CardContent>
      <CardActions>
        <span style={{ flex: 1 }} />
        <Button variant="contained" color="primary" type="submit" disabled={!isValid}>Reset</Button>
      </CardActions>
    </Card>
  </CenteredPage>
}

export default PasswordReset
