import { useCallback, useMemo } from 'react'
import { Box, useMediaQuery, useTheme, List, ListItem, ListItemText, ListItemIcon, Icon, Divider, SpeedDial, SpeedDialIcon, SpeedDialAction, Hidden } from '@mui/material'
import { useAuth } from '../auth/AuthProvider'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { WriteUpdateCard } from '../common/'
import { ManageContent } from './'
import { MeQueryDocument, useDeleteProfileMutation } from '../../graphql'
import { useConfirmationDialog } from '../common/ConfirmationDialog'

function ProfileDashboard() {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))
  const { user } = useAuth()
  const { profileId } = useParams<{ profileId?: string }>()
  const profile = useMemo(() => profileId ? user?.profiles.find(p => p.id === profileId) : undefined, [profileId, user])
  const history = useHistory()
  const location = useLocation()
  const [deleteProfile] = useDeleteProfileMutation({ refetchQueries: [MeQueryDocument], awaitRefetchQueries: true })
  const confirmDialog = useConfirmationDialog()

  const handleDeleteProfile = useCallback((profileId: string) => {
    confirmDialog.open({
      dialogContent: 'Weet je zeker dat je dit profiel wilt verwijderen?',
      onConfirm: async () => {
        await deleteProfile({ variables: { profileId } })
        history.push('/')
      }
    })
  }, [deleteProfile, confirmDialog, history])

  const handleAddEventClick = useCallback(() => {
    history.push('/events/add', { referer: location.pathname, profile })
  }, [history, location, profile])

  return <Box display="flex" flexDirection="column" height="100%">
    <Box display="flex" justifyContent="center" padding={1} flex={1} overflow="auto">
      <Box width={xl ? '75%' : lg ? '66.5%' : md ? '100%' : sm ? '100%' : '100%'}>
        <WriteUpdateCard profile={profile} />
        <ManageContent profile={profile} />
      </Box>
      <Box width={xl ? '25%' : '33.3%'} sx={{ display: { lg: 'block', xs: 'none' } }}>
        <List disablePadding>
          <ListItem button onClick={handleAddEventClick}>
            <ListItemIcon><Icon>event</Icon></ListItemIcon>
            <ListItemText primary="Event toevoegen" />
          </ListItem>
        </List>
        <Divider />
        <List disablePadding dense>
          <ListItem button onClick={() => history.push(profile?.slug ? `/${profile?.slug}` : `/${profile?.type.toLowerCase()}/${profile?.id}`)}>
            <ListItemIcon><Icon fontSize="small">visibility</Icon></ListItemIcon>
            <ListItemText primary="Profiel bekijken" />
          </ListItem>
          <ListItem button onClick={() => history.push(`/profile/${profile?.id}/edit`, { referer: location.pathname })}>
            <ListItemIcon><Icon color="primary" fontSize="small">create</Icon></ListItemIcon>
            <ListItemText primary="Profiel bewerken" primaryTypographyProps={{ color: 'primary' }} />
          </ListItem>
          <ListItem button onClick={() => profile?.id && handleDeleteProfile(profile.id)}>
            <ListItemIcon><Icon color="secondary" fontSize="small">delete</Icon></ListItemIcon>
            <ListItemText primary="Profiel verwijderen" primaryTypographyProps={{ color: 'secondary' }} />
          </ListItem>
        </List>
      </Box>
    </Box>
    <Hidden lgUp>
      <SpeedDial ariaLabel="SpeedDial" icon={<SpeedDialIcon />} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <SpeedDialAction onClick={handleAddEventClick} icon={<Icon>event</Icon>} tooltipTitle="Event toevoegen" />
        <SpeedDialAction onClick={() => history.push(profile?.slug || `/${profile?.type.toLowerCase()}/${profile?.id}`)} icon={<Icon fontSize="small">visibility</Icon>} tooltipTitle="Profiel bekijken" />
        <SpeedDialAction onClick={() => history.push(`/profile/${profile?.id}/edit`, { referer: location.pathname })} icon={<Icon fontSize="small" color="primary">create</Icon>} tooltipTitle="Profiel bewerken" />
        <SpeedDialAction onClick={() => profile?.id && handleDeleteProfile(profile.id)} icon={<Icon fontSize="small" color="secondary">delete</Icon>} tooltipTitle="Profiel verwijderen" />
      </SpeedDial>
    </Hidden>
  </Box>
}

export default ProfileDashboard
