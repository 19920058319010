import { Button, Typography } from '@mui/material'
import { CenteredPage } from '../common'

function Welcome({ isNewUser, onContinue }: { isNewUser: boolean, onContinue: () => void }) {
  return <CenteredPage>
    <Typography variant="h1" gutterBottom>Welkom</Typography>
    {isNewUser && <Typography variant="h5" gutterBottom>Welkom bij Band-Base!</Typography>}
    {!isNewUser && <Typography style={{ maxWidth: 440 }} align="center" gutterBottom>We hebben onze gebruiksvoorwaarden en ons privacy beleid aangepast. In de volgende stap vragen we je om deze aanpassingen goed te keuren.</Typography>}
    {isNewUser && <Typography style={{ maxWidth: 440 }} align="center" gutterBottom>In de volgende stap vragen we je om onze gebruiksvoorwaarden en ons privacy beleid goed te keuren.</Typography>}
    <Button variant="contained" color="primary" size="large" onClick={onContinue}>Volgende</Button>
  </CenteredPage>
}

export default Welcome
