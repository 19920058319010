import { Avatar, Badge, Box, Divider, Drawer, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, styled, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { useHistory, useLocation } from 'react-router'
import { AppBar, SearchField } from '.'
import { ProfileUnion } from '../../graphql'
import { AuthUser, useAuth } from '../auth/AuthProvider'

const drawerWidth = 320

const menuItems = [
  { path: '/browse', title: 'Browse artiesten of events', icon: 'explore' }
]

const legalItems = [
  { path: '/about', title: 'Over ons' },
  { path: '/terms', title: 'Gebruiksvoorwaarden' },
  { path: '/privacy', title: 'Privacy beleid' }
]

const StyledDrawer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth
  }
})

function UserMenu({ user }: { user: AuthUser }) {
  const { logout } = useAuth()
  const { pathname } = useLocation()
  const history = useHistory()

  return <><List disablePadding component="div">
    <ListItem button onClick={() => history.push('/')} selected={pathname === '/'}>
      <ListItemAvatar>
        <Badge badgeContent={3} color="primary">
          <Avatar><img src={user.photoURL || ''} alt={user.displayName || ''} style={{ width: '100%', height: '100%' }} /></Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText primary={user.displayName} secondary={user.email} />
      <ListItemSecondaryAction>
        <Tooltip title="Uitloggen"><IconButton onClick={logout}><Icon>power_settings_new</Icon></IconButton></Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
    <ListItem button dense onClick={() => history.push('/account/settings')} selected={pathname.indexOf('/account/settings') === 0}>
      <ListItemIcon><Icon>settings</Icon></ListItemIcon>
      <ListItemText primary="Account instellingen" />
    </ListItem>
  </List>
    <List disablePadding component="div" dense subheader={
      <ListSubheader component="div" sx={{ bgcolor: 'background.paper', borderBottom: 0 }}>
        Jouw profielen
      </ListSubheader>
    }>
      {(user.profiles as ProfileUnion[]).map((profile, k) => <ListItem key={k} button onClick={() => history.push(`/profile/${profile.id}`)} selected={pathname.indexOf(`/profile/${profile.id}`) === 0}>
        <ListItemAvatar>
          <Avatar sx={{ width: 24, height: 24 }} src={profile.avatar || ''} alt={profile.name.substring(0, 2).toUpperCase()} />
        </ListItemAvatar>
        <ListItemText primary={profile.name} />
      </ListItem>)}
      <ListItem button onClick={() => history.push('/profile/add', { referer: pathname })} selected={pathname.indexOf('/profile/add') === 0}>
        <ListItemIcon><Icon color="primary">add_circle</Icon></ListItemIcon>
        <ListItemText primary="Artiest, band of podium toevoegen" />
      </ListItem>
    </List>
  </>
}

function Sidebar({ open, onClose }: { open: boolean, onClose: () => void }) {
  const history = useHistory()
  const { pathname } = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { user } = useAuth()

  return <StyledDrawer
    variant={isMobile ? 'temporary' : 'permanent'}
    anchor="left"
    open={open}
    onClose={onClose}
  >
    <Box display="flex" flexDirection="column" height="100%">
      <AppBar onHideSidebar={onClose} isInSidebar color="primary" />
      <Box>
        <List disablePadding component="div">
          <ListItem>
            <SearchField />
          </ListItem>
        </List>
        <List disablePadding component="nav">
          {menuItems.map(({ path, title, icon }, k) => (
            <ListItem button key={k} onClick={() => history.push(path)} selected={path === pathname || (path !== '/' && pathname.indexOf(path) === 0)}>
              <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider />
      <Box flex={1} overflow="auto">
        {user ? <UserMenu user={user} /> : <List disablePadding>
          <ListItem button onClick={() => history.push('/signup')}>
            <ListItemIcon><Icon>person_add</Icon></ListItemIcon>
            <ListItemText primary="Account aanmaken" />
          </ListItem>
          <ListItem button dense onClick={() => history.push('/login')}>
            <ListItemIcon><Icon>login</Icon></ListItemIcon>
            <ListItemText primary="Inloggen" />
          </ListItem>
        </List>}
      </Box>
      <Divider />
      <List disablePadding dense component="nav">
        {legalItems.map(({ path, title }, k) => (
          <ListItem key={k} onClick={() => history.push(path)} selected={pathname.indexOf(path) === 0} button>
            <ListItemText primary={title} inset />
          </ListItem>
        ))}
      </List>
    </Box>
  </StyledDrawer>
}

export default Sidebar
