import { getDownloadURL, getMetadata, getStorage, ref } from 'firebase/storage'
import { atom } from 'jotai'

const aboutRefAtom = atom(() => {
  const storage = getStorage()
  return ref(storage, 'about.md')
})

const aboutAtom = atom(async (get) => {
  const aboutRef = get(aboutRefAtom)
  const metadata = await getMetadata(aboutRef)
  const downloadURL = await getDownloadURL(aboutRef)
  const content = atom(async () => {
    return await (await fetch(downloadURL)).text()
  })
  return { ...metadata, downloadURL, content }
})

export default aboutAtom
