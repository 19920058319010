import { atom, useAtom } from 'jotai'
import { RenderMarkdown } from '.'
import { Box } from '@mui/material'
import { aboutAtom } from '../../atoms'

function About() {
  const [about] = useAtom(aboutAtom)
  const [content] = useAtom(about?.content || atom(''))
  return <Box padding={2}><RenderMarkdown>{content}</RenderMarkdown></Box>
}

export default About
